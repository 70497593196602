import React, { useEffect, useRef, useState } from "react";
import { FieldArray, Form, Formik } from "formik";
import { Stepper } from "store/addReportStepper/initialState";
import * as Yup from "yup";

import { useDispatch } from "react-redux";
import {
  ClearAllFilterBar,
  Filters,
  InfoBar,
  SubmissionButtons,
  ChooseFromExistingBoundary,
  DrawOnMap,
} from "./components";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
import { sentenceCase } from "change-case";
import { ISummaryReports } from "store/reportsSummary";
import { setRegionColumnsAction } from "store/addReportStepper/reducer.actions";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { checkIsAnythingUpdatedThisStep } from "../..";
import { Step } from "hooks/useReportsStepper";
import InofIcon from "assets/images/information-circle.svg";

interface ReportGeospatialQueryBuildingProps {
  activeStep: any;
  isLastStep: any;
  steps: Step[];
  reportId: any;
  reportSummary: ISummaryReports;
  fetchReportsSummary: (id: number) => void;
  moveToPreviousStep: () => void;
  moveToNextStep: () => void;
  setAnythingUpdatedThisStep: (currentStep: string, isUpdated: boolean) => void;
}
interface RegionFilterItem {
  column: string;
  condition: string;
  operator: string;
  regions: any[];
}

interface InitialValues {
  filterType: string;
  regionFilter: RegionFilterItem[];
}

// constants
export const FILTERS_OPTIONS = {
  NO_GEOSPATIAL_BOUNDARY: "no_boundary",
  CHOOSE_FROM_EXISTING: "existing_boundary",
  DRAW_ON_MAP: "draw_boundary",
};

const DEFAULT_SELECTED_FILTER = FILTERS_OPTIONS.NO_GEOSPATIAL_BOUNDARY;

export const ReportGeospatialQueryBuilding: React.FC<
  ReportGeospatialQueryBuildingProps
> = ({
  activeStep,
  isLastStep,
  steps,
  reportId,
  fetchReportsSummary,
  reportSummary,
  moveToNextStep,
  moveToPreviousStep,
  setAnythingUpdatedThisStep,
}) => {
  const [initialValues, setInitialValues] = useState<any>({
    filterType: FILTERS_OPTIONS.NO_GEOSPATIAL_BOUNDARY,
    regionFilter: [],
  });

  const { regions } = useSelector<RootState, Stepper>(
    (state) => state?.reportStepper
  );

  const drawRef = useRef<any>(); // for draw on the map mode

  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    filterType: Yup.string().required("Filter Type is required"),
    regionFilter: Yup.array()
      .of(
        Yup.object().shape({
          column: Yup.object()
            .shape({
              name: Yup.mixed().required("Column name is required"),
              value: Yup.mixed().required("Column value is required"),
            })
            .required("Column is required"),
          condition: Yup.string().required("Condition is required"),
          operator: Yup.object()
            .shape({
              name: Yup.mixed().required("Operator name is required"),
              value: Yup.mixed().required("Operator value is required"),
            })
            .required("Operator is required"),

          regions: Yup.array()
            .min(1, "At least one region is required")
            .required("Regions are required"),
        })
      )
      .when("filterType", (filterType: any, schema) => {
        return filterType[0] === FILTERS_OPTIONS.NO_GEOSPATIAL_BOUNDARY
          ? schema.notRequired()
          : schema.min(1, "Region filter is required");
      }),

    // .min(1, "Region filter is required")
    // .test("filterType", "Region filter is required", function (value: any) {
    //   const { filterType } = this.parent;

    //   if (filterType === FILTERS_OPTIONS.NO_GEOSPATIAL_BOUNDARY) {
    //     return false;
    //   }
    //   return true;

    // }),

    // .required("Region filter is required"),
  });

  const regionsList = async () => {
    try {
      const { data } =
        await apiLibrary.Reports.AddReportApis.getReportGeospatialColumns(
          reportId
        );
      const regionsData = data?.map(
        (item: { name: string; title: string; type: string }) => ({
          value: item?.name,
          name: item?.title,
          type: item?.type,
        })
      );
      dispatch(setRegionColumnsAction(regionsData));
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);
    }
  };

  useEffect(() => {
    regionsList();
  }, [reportId]);

  const handleSubmit = async (
    values: any,
    { setSubmitting }: any
  ): Promise<void> => {
    const updatedValues = {
      ...values,
      regionFilter: createFormValues(values.filterType, values),
    };

    if (!checkIsAnythingUpdatedThisStep(steps, activeStep.step)) {
      moveToNextStep();
      return;
    }
    try {
      const { data } =
        await apiLibrary.Reports.AddReportApis.addReportGeospatialFilter(
          reportSummary.id,
          updatedValues
        );
      await fetchReportsSummary(reportSummary.id);
      moveToNextStep();
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(sentenceCase(errorMsg ?? ""));
    } finally {
      setSubmitting(false);
    }
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={handleSubmit}
    >
      {({
        values,
        setFieldValue,
        setFieldError,
        isSubmitting,
        handleBlur,
        handleChange,
        touched,
        isValid,
        errors,
        dirty,
      }) => {
        const handleClickOnClearAllBtn = () => {
          setFieldValue("regionFilter", []);

          // remove all drawn shapes from the map
          if (drawRef.current) {
            drawRef.current.deleteAll();
          }
        };

        useEffect(() => {
          if (regions?.length > 0 && reportSummary) {
            setFieldValue(
              "filterType",
              reportSummary.geospatialFilterType ??
                FILTERS_OPTIONS.NO_GEOSPATIAL_BOUNDARY
            );
            setFieldValue(
              "regionFilter",
              convertDataFormat(reportSummary.geospatialFilter, regions)
            );
          }
        }, [reportSummary, regions]);
        useEffect(() => {
          setAnythingUpdatedThisStep("geospatialFiltering", dirty);
        }, [dirty]);
        return (
          <Form>
            <div className="flex flex-col w-full gap-4 mt-2 rounded-lg bg-bgWhite dark:bg-secondaryLight">
              <div className="flex items-center gap-2 text-sm font-Overpass text-textMidLight dark:text-caption">
                <img src={InofIcon} alt="info" className="mb-[3px]" />
                <p className="dark:text-textMain">
                  To search for observations related to an existing region or
                  shape in the system, use the ‘Choose from preloaded layers’
                  option. To define one or more areas not yet added to the
                  system, use the 'Draw on Map' feature. To add one or more
                  additional geographic filters specific to your data form,
                  click + Add Another.
                </p>
              </div>

              <ClearAllFilterBar
                handleClickOnClearAllBtn={handleClickOnClearAllBtn}
              />
              <Filters
                handleFiltersOnChange={(e: any) => {
                  setFieldValue("filterType", e.target.value);
                  setFieldValue("regionFilter", []);
                }}
                defaultValue={values.filterType}
              />

              {renderedFilterView(
                values.filterType ?? FILTERS_OPTIONS.NO_GEOSPATIAL_BOUNDARY,
                reportId,
                errors,
                values,
                touched,
                handleBlur,
                setFieldValue,
                isSubmitting,
                reportSummary,
                drawRef
              )}
              <SubmissionButtons
                // isValid={ values.filterType!==FILTERS_OPTIONS.NO_GEOSPATIAL_BOUNDARY && isValid}
                isSubmitting={isSubmitting}
                handleGoBackBtn={() => moveToPreviousStep()}
                values={values}
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

const renderedFilterView = (
  selectedOption: any,
  reportId: any,
  errors: any,
  values: any,
  touched: any,
  handleBlur: any,
  setFieldValue: any,
  isSubmitting: any,
  editData: any,
  drawRef: any
) => {
  switch (selectedOption) {
    case FILTERS_OPTIONS.DRAW_ON_MAP:
      return (
        <DrawOnMap
          selectedOption={selectedOption}
          reportId={reportId}
          errors={errors}
          values={values}
          touched={touched}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          isSubmitting={isSubmitting}
          editData={editData.geospatialFilter}
          drawRef={drawRef}
        />
      );
    case FILTERS_OPTIONS.CHOOSE_FROM_EXISTING:
      return (
        <ChooseFromExistingBoundary
          selectedOption={selectedOption}
          reportId={reportId}
          errors={errors}
          values={values}
          touched={touched}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          isSubmitting={isSubmitting}
          reportSummary={editData}
        />
      );
    default:
      return null;
  }
};

function createFormValues(selectedOption: string, values: any) {
  if (selectedOption === FILTERS_OPTIONS.CHOOSE_FROM_EXISTING) {
    return values.regionFilter.map((regionFilter: any) => {
      return {
        column: regionFilter.column.value,
        columnTitle: regionFilter?.column?.name,
        type: regionFilter?.column?.type,
        condition: regionFilter?.condition,
        operator: regionFilter?.operator?.value,
        regions: regionFilter?.regions?.map((region: any) => {
          return region?.id;
        }),
      };
    });
  } else if (selectedOption === FILTERS_OPTIONS.DRAW_ON_MAP) {
    return values?.regionFilter.map((regionFilter: any) => {
      return {
        column: regionFilter?.column?.value,
        columnTitle: regionFilter?.column?.name,
        type: regionFilter?.column?.type,
        condition: regionFilter?.condition,
        operator: regionFilter?.operator?.value,
        regions: regionFilter?.regions?.map((region: any, index: number) => {
          return {
            description: `My Region  ${index}`,
            map_data: {
              features: [region],
              type: "FeatureCollection",
            },
            name: `Region ${index}`,
            type: "region",
          };
        }),
      };
    });
  } else if (selectedOption === FILTERS_OPTIONS.NO_GEOSPATIAL_BOUNDARY) {
    return [];
  }
}

function convertDataFormat(data: any[], regionsColumns: any[]) {
  return data?.map(({ filter }) => {
    const columnMatch = regionsColumns?.find((item: any) => {
      return item?.value.toLowerCase() === filter?.column.toLowerCase();
    });
    const column = columnMatch
      ? {
          name: columnMatch?.name,
          value: columnMatch?.value,
          type: columnMatch?.type,
        }
      : { name: "", value: "", type: "" };

    const operator = {
      name: filter?.operator?.toUpperCase(),
      value: filter?.operator,
    };

    return {
      column: column,
      condition: filter?.condition,
      operator,
      type: column.type,
      regions: filter?.regions,
    };
  });
}
