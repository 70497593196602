export const UPDATE_SHAPE_PICKER = "UPDATE_SHAPE_PICKER";

export const UPDATE_SHAPE_PICKERS = "UPDATE_SHAPE_PICKERS";

export const UPDATE_POINT_PICKER = "UPDATE_POINT_PICKER";

export const UPDATE_POINT_PICKERS = "UPDATE_POINT_PICKERS";

export const UPDATE_DRAW_POLYGON = "UPDATE_DRAW_POLYGON";

export const UPDATE_DRAW_POLYGONS = "UPDATE_DRAW_POLYGONS";

export const UPDATE_DROP_PIN = "UPDATE_DROP_PIN";

export const UPDATE_DROP_PINS = "UPDATE_DROP_PINS"

export const DELETED_QUESTION_ELEMENT = "DELETED_QUESTION_ELEMENT";

export const SELECTED_QUESTION_ELEMENT = "SELECTED_QUESTION_ELEMENT"; 

export const ADDED_QUESTION_STATE = "ADDED_QUESTION_STATE";

export const UPDATE_ALL_COMMUNITY_LAYERS = "UPDATE_ALL_COMMUNITY_LAYERS";

export const UPDATE_ALL_COMMUNITY_POINTS = "UPDATE_ALL_COMMUNITY_POINTS";

export const UPDATE_ALL_GLOBAL_COMMUNITY_LAYERS = "UPDATE_ALL_GLOBAL_COMMUNITY_LAYERS";

export const UPDATE_CURRENT_QUESTION = "UPDATE_CURRENT_QUESTION";

export const ADDED_HARVEST_PANEL_MAP_QUESTIONS_STATE = "ADDED_HARVEST_PANEL_MAP_QUESTIONS_STATE";

export const ADDED_GEOGRAPHICAL_PANEL_MAP_QUESTIONS_STATE = "ADDED_GEOGRAPHICAL_PANEL_MAP_QUESTIONS__STATE";

export const UPDATE_ALL_COMMUNITY_LAYERS_FETCHING_PROGRESS = "UPDATE_ALL_COMMUNITY_LAYERS_FETCHING_PROGRESS";

export const UPDATE_ALL_COMMUNITY_POINTS_FETCHING_PROGRESS = "UPDATE_ALL_COMMUNITY_POINTS_FETCHING_PROGRESS";

export const UPDATE_ALL_GLOBAL_COMMUNITY_LAYERS_FETCHING_PROGRESS = "UPDATE_ALL_GLOBAL_COMMUNITY_LAYERS_FETCHING_PROGRESS";

export const UPDATE_SURVEY_ACTIVE_TAB = "UPDATE_SURVEY_ACTIVE_TAB";

export const UPDATE_GET_GPS_DATAS = "UPDATE_GET_GPS_DATAS";

export const DISPLAY_GEOGRAPHY_VIEW = "DISPLAY_GEOGRAPHY_VIEW";

export const DISPLAY_SURVEY_VIEW = "DISPLAY_SURVEY_VIEW";

export const UPDATE_SELECTED_LAYERS_ITEMS_DATA = "UPDATE_SELECTED_LAYERS_ITEMS_DATA";

export const UPDATE_CHOOSED_LAYERS_ITEMS_DATA = "UPDATE_CHOOSED_LAYERS_ITEMS_DATA";

export const SET_FORM_JSON = "SET_FORM_JSON";

export const SET_DRAW_SHAPE_MODE = "SET_DRAW_SHAPE_MODE";

export const UPDATE_DO_REMOVE_ALL_SHAPE_SIGNAL = "UPDATE_DO_REMOVE_ALL_SHAPE_SIGNAL";

export const SET_DRAW_SHAPE_GEOMETRY = "SET_DRAW_SHAPE_GEOMETRY";

export const DO_RESET_MAP = "DO_RESET_MAP";

export const SET_DRAW_REFERENCE = "SET_DRAW_REFERENCE";

export const SET_MAP = "SET_MAP";

export const SET_FORM_ALL_SELECTED_SHAPES = "SET_FORM_ALL_SELECTED_SHAPES";

export const SET_FORM_ALL_SELECTED_DEFAULT_LAYERS = "SET_FORM_ALL_SELECTED_DEFAULT_LAYERS";

export const SET_UPLOADED_LAYERS = "SET_UPLOADED_LAYERS";

export const REMOVE_UPLOADED_ITEM = "REMOVE_UPLOADED_ITEM";


export const SET_ACTIVE_AREA_SELECT_MODE = "SET_ACTIVE_AREA_SELECT_MODE";

export  const SET_IS_SHAPE_DRAWING_COMPLETED = "SET_IS_SHAPE_DRAWING_COMPLETED";

export const SELECTED_UPLOADED_ITEM_TO_HIGHLIGHT = "SELECTED_UPLOADED_ITEM_TO_HIGHLIGHT";

export const START_REFRESHING_DYNAMIC_FORM_GEOGRAPHIES = "START_REFRESHING_DYNAMIC_FORM_GEOGRAPHIES";
export const STOP_REFRESHING_DYNAMIC_FORM_GEOGRAPHIES = "STOP_REFRESHING_DYNAMIC_FORM_GEOGRAPHIES";
