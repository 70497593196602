import React, { useState } from "react";
import { SeeMoreText } from "./SeeMoreText";
import useDateFormatter from "hooks/formatDateWithPattern";
import moment from "moment";
import { DATE_FORMATS } from "constants/dateFormats";

interface IProps {
  id: number;
  name: string;
  createdBy: any;
  dateCreated: string;
  recurrenceSettings: string;
  programFilter: any;
  formFitler: any;
  organizationFitler: any;
  communitiesFilter: any;
}

export const SummaryHeaders: React.FC<IProps> = ({
  id,
  name,
  createdBy,
  dateCreated,
  recurrenceSettings,
  programFilter,
  formFitler,
  organizationFitler,
  communitiesFilter,
}) => {
  const { formatDate } = useDateFormatter();
  const programFilterText = programFilter?.map(
    (program: any, index: number) => (
      <span key={index}>
        <span>{program.name}</span>
        {index !== programFilter.length - 1 && ", "}
      </span>
    )
  );

  const formFilterText = formFitler?.map((form: any, index: any) => (
    <span key={index}>
      {form.name}
      {index !== formFitler.length - 1 && ", "}
    </span>
  ));

  const organizationFilterText = organizationFitler?.map(
    (organization: any, index: any) => (
      <span key={index}>
        {organization.name}
        {index !== organizationFitler.length - 1 && ", "}
      </span>
    )
  );

  const communitiesFilterText = communitiesFilter?.map(
    (community: any, index: any) => (
      <span key={index}>
        {community.name}
        {index !== communitiesFilter.length - 1 && ", "}
      </span>
    )
  );

  return (
    <>
      <div className="py-4 border-b border-lineMid dark:border-lineLight">
        <div className="py-2">
          <p className="mb-1 text-sm text-textMidLight dark:text-caption">
            Report Name
          </p>
          <p className="text-lg font-semibold capitalize text-textMid dark:text-textMain">
            {name}
          </p>
        </div>
        <div className="">
          <div className="flex py-2 ">
            <div className="w-1/2">
              <p className="mb-1 text-sm text-textMidLight dark:text-caption">
                Created By
              </p>
              <p className="text-sm dark:text-textMain">{createdBy?.name}</p>
            </div>
            <div className="w-1/2">
              <p className="mb-1 text-sm text-textMidLight dark:text-caption">
                Date Created
              </p>
              <p className="text-sm dark:text-textMain">
                {moment.utc(dateCreated).format("DD MMM YYYY, hh:mm A")}
              </p>
            </div>
          </div>
          <div className="w-full py-2">
            <p className="mb-1 text-sm text-textMidLight dark:text-caption">
              Report Schedule
            </p>
            <p className="text-sm leading-tight dark:text-textMain ">
              {recurrenceSettings}
            </p>
          </div>
          <div className="w-full py-2">
            <p className="mb-1 text-sm text-textMidLight dark:text-caption">
              Programs Filter
            </p>
            <SeeMoreText text={programFilterText} />
          </div>
          <div className="w-full py-2">
            <p className="mb-1 text-sm text-textMidLight dark:text-caption">
              Forms Filter{" "}
            </p>
            <SeeMoreText text={formFilterText} />
          </div>
          <div className="w-full py-2">
            <p className="mb-1 text-sm text-textMidLight dark:text-caption">
              Organizations Filter
            </p>
            <SeeMoreText text={organizationFilterText} />
          </div>
          <div className="w-full py-2 break-words">
            <p className="mb-1 text-sm text-textMidLight dark:text-caption">
              Communities Filter
            </p>
            <SeeMoreText text={communitiesFilterText} />
          </div>
        </div>
      </div>
    </>
  );
};
