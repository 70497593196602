import React, { useEffect, useState } from "react";
/************ hooks ************/
import useDateFormatter from "hooks/formatDateWithPattern";
/************ Assets ************/
import { CalenderIcon, CheveronDownIcon } from "assets/icons/HeroIcons";
import { EyeIcon, EyeOffIcon } from "assets/icons/HeroIcons";
import SearchIcon from "assets/images/Auth/Vector.png";
/************ External Library ************/
import { Select as MaterialUiSelect } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/system";
import { CountryCode, getExampleNumber } from "libphonenumber-js";
import { PhoneNumberUtil, PhoneNumberFormat } from "google-libphonenumber";

import {
  Field,
  FieldProps,
  FormikContext,
  FormikProps,
  useFormikContext,
} from "formik";
/************ Styles ************/
import "./TextInputs.scss";
import { sentenceCase } from "change-case";
import classnames from "classnames";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import "./TextInputs.scss";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

/**
 * A custom icon component.
 * @param {any} props - Component props containing 'open' and 'onClick' properties.
 */

const CustomIcon = (props: any) => {
  const { open, onClick } = props;

  return (
    <CheveronDownIcon
      height={16}
      width={16}
      onClick={onClick}
      className={`-0 -0 w-6 h-6 relative ${open ? "transform rotate-180" : ""}`}
      preserveAspectRatio="xMidYMid meet"
      style={{ marginRight: "12px", cursor: "pointer" }}
    />
  );
};

const CustomOutlinedInput = styled(OutlinedInput)`
  &:hover:not($disabled):not($focused):not($error) $notchedOutline {
    border-color: transparent;
  }
  &.Mui-focused $notchedOutline {
    border-color: transparent;
  }
`;

interface IProps {
  data?: any[];
  value?: any;
  label?: string; // Add label prop to customize the label text
  placeholder?: string; // Add placeholder prop to customize the placeholder text
  updateUserProfileSettings?: any;
  name?: any;
  multiple: any;
  startAdornment?: any;
  disabled?: any;
  endAdornment?: any;
}

const SelectInput: React.FC<IProps> = ({
  data,
  value,
  label = "Select",
  placeholder = "",
  updateUserProfileSettings,
  name,
  multiple,
  disabled,
  startAdornment,
  endAdornment,
}) => {
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <>
      <FormControl className="">
        <MaterialUiSelect
          endAdornment={endAdornment}
          startAdornment={startAdornment}
          disabled={disabled}
          className="w-full h-10 capitalize selectremove_active dark:bg-secondaryLight dark:border-lineLight"
          displayEmpty
          value={value}
          name={name}
          multiple={multiple}
          onChange={updateUserProfileSettings}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          renderValue={(selected) => {
            if (selected === "") {
              return (
                <p className="pl-3 dark:text-caption">
                  {placeholder ||
                    (data && data.length > 0 ? data[0]?.name : "")}
                </p>
              );
            }

            const selectedItems = Array.isArray(selected)
              ? selected.map((item) =>
                  typeof item === "string" ? item : item?.name || ""
                )
              : typeof selected === "string"
                ? [selected]
                : [selected?.name || ""];

            return (
              <p className="dark:text-textMain">{selectedItems.join(", ")}</p>
            );
          }}
          MenuProps={MenuProps}
          inputProps={{ "aria-label": "Without label" }}
          IconComponent={CustomIcon}
          open={open}
          onClick={handleToggle}
          input={<CustomOutlinedInput />}
        >
          {data?.map((item) => {
            if (typeof item === "string") {
              return (
                <MenuItem
                  key={item}
                  value={item}
                  className="menu_items dark:bg-secondaryLight dark:text-caption"
                >
                  {sentenceCase(item)}
                </MenuItem>
              );
            } else if (typeof item === "object") {
              return (
                <MenuItem
                  key={item.id}
                  value={item}
                  className="menu_items dark:bg-secondaryLight dark:text-caption"
                >
                  {item.name}
                </MenuItem>
              );
            } else {
              return <></>;
            }
          })}
        </MaterialUiSelect>
      </FormControl>
    </>
  );
};

interface TextInputProps {
  label: any;
  type: string;
  error?: any;
  name: string;
  usercolumn?: any;
  editMode?: boolean;
  value?: any;
  fieldAs?:
    | "input"
    | "text"
    | "select"
    | "date"
    | "password"
    | "textarea"
    | "phone"
    | "date";
  data?: any[];
  handleChange?: any;
  handleBlur?: any;
  touched?: any;
  setFieldValue?: any;
  placeholder?: any;
  rows?: number;
  multiple?: any;
  disabled?: any;
  max?: any;
  ref?: any;
  id?: any;
  inputClassName?: string;
  height?: any;
  isColumnInput?: any;
  min?: any;
  style?: any;
  endAdornment?: any;
  startAdornment?: any;
  step?: any;
  onFocus?: any;
  placeholderLight?: any;
  helperText?: string | null;
  onKeyDown?: any;
  helperTextType?: "info" | "warning";
  countryChange?: (country: CountryCode) => void;
  isEmptyError?: boolean;
}

const TextInput: React.FC<TextInputProps> = ({
  label,
  type,
  error,
  name,
  editMode = true,
  value,
  fieldAs = "input",
  data,
  handleChange,
  handleBlur,
  touched,
  rows = 4,
  setFieldValue,
  placeholder,
  multiple,
  disabled,
  max,
  min,
  ref,
  id,
  inputClassName,
  height,
  isColumnInput,
  style,
  endAdornment,
  startAdornment,
  step,
  onFocus,
  placeholderLight,
  helperText = null,
  onKeyDown,
  helperTextType,
  countryChange,
  isEmptyError,
  ...restProps
}) => {
  const { formatDate } = useDateFormatter();
  return (
    <div className="flex flex-col flex-grow w-full rounded-lg">
      <div className="relative flex flex-col py-2">
        {editMode ? (
          <p
            className={`flex-grow pb-1 w-full text-sm font-medium text-left capitalize text-secondaryMid dark:text-caption ${
              touched && error ? "text-accent_1Dark dark:text-accent_1Dark" : ""
            }`}
          >
            {label}
          </p>
        ) : (
          <p className="w-full pb-2 text-sm text-left capitalize text-secondaryMidLight dark:text-caption ">
            {label}
          </p>
        )}
        {editMode ? (
          <>
            <RenderInput
              label={label}
              type={type}
              error={error}
              name={name}
              editMode={editMode}
              value={value}
              fieldAs={fieldAs}
              data={data}
              handleChange={handleChange}
              handleBlur={handleBlur}
              touched={touched}
              rows={4}
              setFieldValue={setFieldValue}
              placeholder={placeholder}
              multiple={multiple}
              disabled={disabled}
              max={max}
              min={min}
              ref={ref}
              id={id}
              inputClassName={inputClassName}
              height={height}
              isColumnInput={isColumnInput}
              style={style}
              endAdornment={endAdornment}
              startAdornment={startAdornment}
              step={step}
              onFocus={onFocus}
              placeholderLight={placeholderLight}
              helperText={helperText}
              onKeyDown={onKeyDown}
              helperTextType={helperTextType}
              countryChange={countryChange}
              isEmptyError={isEmptyError}
              {...restProps}
            />
          </>
        ) : (
          <>
            <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
              <p className="w-full max-w-full max-h-32 overflow-hidden text-overflow-ellipsis whitespace-pre-line text-[15px] text-left font-normal text-secondary dark:text-inputText">
                {type === "date"
                  ? formatDate(value === undefined ? " " : value)
                  : value}
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default TextInput;

const RenderInput: React.FC<TextInputProps> = ({
  label,
  type,
  error,
  name,
  editMode = true,
  value,
  fieldAs = "input",
  data,
  handleChange,
  handleBlur,
  touched,
  rows = 4,
  setFieldValue,
  placeholder,
  multiple,
  disabled,
  max,
  min,
  ref,
  id,
  inputClassName,
  height,
  isColumnInput,
  style,
  endAdornment,
  startAdornment,
  step,
  onFocus,
  placeholderLight,
  helperText = null,
  onKeyDown,
  countryChange,
  isEmptyError,
  ...restProps
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const isPasswordInput = type === "password";

  const formik = useFormikContext();

  const [country, setCountry] = useState<CountryCode>("US");
  const [exampleNumber, setExampleNumber] = useState("");

  const phoneUtil = PhoneNumberUtil.getInstance();

  const getExampleNumber = async (countryCode: string) => {
    try {
      const exampleNum = phoneUtil.getExampleNumber(countryCode);
      if (exampleNum) {
        const formattedExampleNum = phoneUtil.format(
          exampleNum,
          PhoneNumberFormat.INTERNATIONAL
        );
        await setExampleNumber(formattedExampleNum);
        setCountry((pre: CountryCode) => {
          return country;
        });
      } else {
        setExampleNumber("Please Enter Your  Number");
      }
    } catch (error) {
      console.error("Error fetching example number:", error);
      setExampleNumber("Error fetching example number");
    }
  };

  useEffect(() => {
    getExampleNumber(country);
  }, []);

  switch (fieldAs) {
    case "select":
      return (
        <>
          <SelectInput
            disabled={disabled}
            data={data}
            value={value}
            label={label}
            placeholder={placeholder}
            updateUserProfileSettings={handleChange}
            name={name}
            multiple={multiple}
            startAdornment={startAdornment}
            endAdornment={endAdornment}
          />
          <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative py-0.5">
            {touched && error && (
              <p className="flex-grow text-xs text-left text-accent_1Dark">
                {error}
              </p>
            )}
          </div>
        </>
      );
    case "phone":
      return (
        <>
          <div
            className={classnames(
              "flex",
              "justify-start",
              "items-center",
              "w-full",
              "focus:outline-none",
              "gap-1.5",
              "rounded",
              "bg-white",
              "border",

              {
                "border-accent_1Dark": (touched && error) || isEmptyError,
                "border-lineDark dark:border-lineLight":
                  (!touched || !error) && !isEmptyError,
              }
            )}
          >
            <PhoneInput
              limitMaxLength={true}
              disabled={disabled}
              placeholder={exampleNumber}
              defaultCountry={country}
              name={name}
              onCountryChange={(country: CountryCode) => {
                getExampleNumber(country);
                if (countryChange !== undefined) {
                  countryChange(country);
                }
              }}
              value={value}
              onChange={(value) => {
                if (value !== undefined) {
                  handleChange(value);
                  setFieldValue(name, value);
                } else {
                  setFieldValue(name, "");
                  handleChange("");
                }
              }}
              style={{ height: `${height}px` }}
              onBlur={() => formik.setFieldTouched(name, true, true)}
              className={`w-full h-full focus:outline-none   ${
                inputClassName ?? ""
              } dark:bg-secondaryLight resize-none gap-1.5 px-3  dark:text-inputText dark:placeholder:text-inputText  PhoneInputInput`}
            />
          </div>
          <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative py-0.5">
            {touched && error ? (
              <p className="flex-grow capitalize w-[1/2] text-xs text-left text-accent_1Dark">
                {error}
              </p>
            ) : (
              <>
                {helperText !== null && (
                  <p className="flex-grow w-full text-xs text-left text-textMidLight dark:text-textMain">
                    {helperText}
                  </p>
                )}
              </>
            )}
          </div>
        </>
      );

    default:
      return (
        <>
          <div
            className={classnames(
              "flex",
              "justify-start",
              "items-center",
              "w-full",
              "focus:outline-none",
              "gap-1.5",
              "rounded",
              "bg-white",
              "border",
              `input-${type}-wrapper`,
              {
                "pr-3": isPasswordInput,
                "border-accent_1Dark": touched && error,
                "border-lineDark dark:border-lineLight": !touched || !error,
              },
            )}
          >
            {isColumnInput && (
              <button type="button" className="focus:outline-none">
                <img src={SearchIcon} alt="" />
              </button>
            )}
            {/* Placeholder will only work when data comes from UserColumn modal */}
            <Field
              disabled={disabled}
              as={fieldAs}
              rows={rows}
              type={showPassword ? "text" : type}
              className={
                "w-full h-full focus:outline-none dark:bg-secondaryLight resize-none gap-1.5 px-3 py-[9px] dark:text-inputText dark:placeholder:text-inputText " +
                (inputClassName ?? "") +
                `input-${type}`
              }
              step={step}
              name={name}
              max={max}
              min={min}
              onChange={handleChange}
              placeholder={placeholder}
              ref={ref}
              id={id}
              onBlur={handleBlur}
              value={value}
              onFocus={onFocus}
              onKeyDown={onKeyDown}
              style={{
                ...style,
                height: `${height}px`,
                textTransform: type === "date" ? "uppercase" : "unset",
                color:
                  placeholderLight === "changeColor" && !value && "#9B9FA5",
                // paddingTop: type === "date" ? "19px" : "",
                // paddingBottom: type === "date" ? "19px" : "",
              }}
              {...restProps}
            />
            {type === "date" && (
              <CalenderIcon className="calender-icon z-[1] absolute right-[15px] w-4 h-4 pointer-events-none" />
            )}
            {isPasswordInput && (
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="focus:outline-none "
              >
                {showPassword ? (
                  <EyeIcon className="w-6 h-6" />
                ) : (
                  <EyeOffIcon className="w-6 h-6" />
                )}
              </button>
            )}
          </div>

          <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative py-0.5 ">
            {error ? (
              <p className="flex-grow capitalize w-[1/2] text-xs text-left text-accent_1Dark">
                {error}
              </p>
            ) : (
              <>
                {helperText !== null && (
                  <p className="flex-grow w-full text-xs text-left text-textMidLight dark:text-textMain">
                    {helperText}
                  </p>
                )}
              </>
            )}
          </div>
        </>
      );
  }
};
