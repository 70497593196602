import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { generatingLayerId } from "../utils";
import { useDispatch } from "react-redux";
import {
  resetMap,
  updateDrawShapeCoordinates,
  updateSelectedLayersItems,
} from "store/geography";

const useResetMapOnRequest = (
  map: any,
  drawRef: any,
  selectedMarkers: any,
  setSelectedMarkers: any,
  clickedPointForSelectPointForm?: any
) => {
  const { doResetMap, selectedLayersItems, currentLayerToDisplayOnMap } =
    useSelector((state: RootState) => state.geography);

  const dispatch = useDispatch();

  useEffect(() => {
    if (doResetMap && map.current && map.current.isStyleLoaded()) {
      // removing existing popups
      const existingPopups = document.querySelectorAll(".mapboxgl-popup");
      existingPopups?.forEach((popup) => popup?.remove());

      // remove drawn layers from map
      if (selectedLayersItems) {
        const allPaintedLayers = map.current
          ?.getStyle()
          ?.layers.filter((l: any) => l.id.includes("layer"));

        if (allPaintedLayers?.length > 0) {
          allPaintedLayers.forEach((l: any) => {
            map.current.removeLayer(l.id);
            map.current.removeSource(l.source);
          });
        }
        // selectedLayersItems.forEach((l: any) => {
        //   const { layerId, sourceId } = generatingLayerId
        //     l?.itemType,
        //     l?.item?.id
        //   );

        //   if (map.current.getLayer(layerId)) {
        //     map.current.removeLayer(layerId);
        //   }

        //   if (map.current.getSource(sourceId)) {
        //     map.current.removeSource(sourceId);
        //   }
        // });

        dispatch(updateSelectedLayersItems(null));
      }
      if (clickedPointForSelectPointForm) {
        // map.current.removeLayer("slectedPointWhileAddingPoint-123");
        // map.current.removeSource("slectedPointWhileAddingPoint-123");

        // Remove layer if it already exists
        if (map.current.getLayer("slectedPointWhileAddingPoint-123")) {
          map.current.removeLayer("slectedPointWhileAddingPoint-123");
        }

        // Remove source if it already exists
        if (map.current.getSource("slectedPointWhileAddingPoint-123")) {
          map.current.removeSource("slectedPointWhileAddingPoint-123");
        }
      }

      // remove drawn markers from map
      if (selectedMarkers) {
        selectedMarkers.forEach((m: any) => {
          m.marker.remove();
        });

        setSelectedMarkers([]);
      }

      // remove drawn shapes from map
      if (drawRef) {
        drawRef.deleteAll();
        dispatch(updateDrawShapeCoordinates(null));
      }

      // remove raster layer of preview mode
      if (
        currentLayerToDisplayOnMap &&
        currentLayerToDisplayOnMap.type === "raster"
      ) {
        const { layerId, sourceId } = generatingLayerId(
          currentLayerToDisplayOnMap.type,
          currentLayerToDisplayOnMap.id
        );
        if (map.current.getLayer(layerId)) {
          map.current.removeLayer(layerId);
        }

        if (map.current.getSource(sourceId)) {
          map.current.removeSource(sourceId);
        }
      }
    }

    // reset resetMap state
    if (doResetMap) {
      dispatch(resetMap(false));
    }
  }, [doResetMap]);

  return null;
};

export { useResetMapOnRequest };
