import * as React from "react";
import type { SVGProps } from "react";

interface IconProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  isActive?: boolean;
}

export const BarIcon = ({
  fill = "black",
  isActive,
  ...restProps
}: IconProps) => (
  <svg
    width={18}
    height={18}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="flex-grow-0 flex-shrink-0 w-7 h-7 relative"
    {...restProps}
  >
    <path
      d="M13.1875 1.375H0.8125C0.663316 1.375 0.520242 1.31574 0.414753 1.21025C0.309263 1.10476 0.25 0.961684 0.25 0.8125C0.25 0.663316 0.309263 0.520242 0.414753 0.414753C0.520242 0.309263 0.663316 0.25 0.8125 0.25H13.1875C13.3367 0.25 13.4798 0.309263 13.5852 0.414753C13.6907 0.520242 13.75 0.663316 13.75 0.8125C13.75 0.961684 13.6907 1.10476 13.5852 1.21025C13.4798 1.31574 13.3367 1.375 13.1875 1.375ZM10.9375 5.50037H0.8125C0.663316 5.50037 0.520242 5.44111 0.414753 5.33562C0.309263 5.23013 0.25 5.08706 0.25 4.93787C0.25 4.78869 0.309263 4.64562 0.414753 4.54013C0.520242 4.43464 0.663316 4.37537 0.8125 4.37537H10.9375C11.0867 4.37537 11.2298 4.43464 11.3352 4.54013C11.4407 4.64562 11.5 4.78869 11.5 4.93787C11.5 5.08706 11.4407 5.23013 11.3352 5.33562C11.2298 5.44111 11.0867 5.50037 10.9375 5.50037ZM12.0625 9.62463H0.8125C0.663316 9.62463 0.520242 9.56536 0.414753 9.45987C0.309263 9.35438 0.25 9.21131 0.25 9.06213C0.25 8.91294 0.309263 8.76987 0.414753 8.66438C0.520242 8.55889 0.663316 8.49963 0.8125 8.49963H12.0625C12.2117 8.49963 12.3548 8.55889 12.4602 8.66438C12.5657 8.76987 12.625 8.91294 12.625 9.06213C12.625 9.21131 12.5657 9.35438 12.4602 9.45987C12.3548 9.56536 12.2117 9.62463 12.0625 9.62463ZM12.625 13.75H0.8125C0.663316 13.75 0.520242 13.6907 0.414753 13.5852C0.309263 13.4798 0.25 13.3367 0.25 13.1875C0.25 13.0383 0.309263 12.8952 0.414753 12.7898C0.520242 12.6843 0.663316 12.625 0.8125 12.625H12.625C12.7742 12.625 12.9173 12.6843 13.0227 12.7898C13.1282 12.8952 13.1875 13.0383 13.1875 13.1875C13.1875 13.3367 13.1282 13.4798 13.0227 13.5852C12.9173 13.6907 12.7742 13.75 12.625 13.75Z"
      fill={fill}
      className={`dark:${
        isActive ? "fill-primary" : "fill-caption group-hover:dark:fill-primary"
      }`}
    />
  </svg>
);
export default BarIcon;
