import usePermissions from "hooks/usePermissions";
import { OptionsModalProps } from "../../types";
import { BasicBtn } from "../index";
import { DetailedBtn } from "../index";

const OptionsModal = ({
  handleClickToCreateReport,
  isOpen,
}: OptionsModalProps) => {
  const { reports } = usePermissions();
  if (isOpen) {
    return (
      <div
        className="absolute flex flex-col items-start justify-start mt-1 bg-white rounded-lg top-12 right-3 z-10 bg-textWhite"
        style={{
          boxShadow:
            "0px 2px 8px 0 rgba(2,13,36,0.14), 0px 2px 24px 0 rgba(2,13,36,0.08)",
        }}
      >
        <div className="flex flex-col justify-start items-center self-stretch flex-grow-0 flex-shrink-0 px-1.5 py-1">
          {reports.canCreateBasicReports && (
            <BasicBtn handleClickToCreateReport={handleClickToCreateReport} />
          )}
          {reports.canCreateReports && (
            <DetailedBtn
              handleClickToCreateReport={handleClickToCreateReport}
            />
          )}
        </div>
      </div>
    );
  }
  return <></>;
};

export { OptionsModal };
