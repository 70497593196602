import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { RootState } from "store";
import {
  updateAddedGeographicalPanelMapQuestions,
  updateAddedHarvestPanelMapQuestions,
  updateAddedQuestionState,
} from "store/surveyJS";
import questionTypes from "../questionTypes";

const useAppendCommunityToCurrentQuestion = () => {
  const dispatch = useDispatch();
  const {
    addedQuestionState,
    allCommunityLayers,
    allCommunityPoints,
    allGlobalCommunityLayers,
    harvestPanelMapQuestions,
    geographicalPanelMapQuestions
  } = useSelector((state: RootState) => state.surveyJS);

  useEffect(() => {
    if (allCommunityLayers && allCommunityLayers.length > 0) {
      if (
        addedQuestionState &&
        addedQuestionState?.allCommunityLayers?.length == 0 &&
        (addedQuestionState.type === questionTypes.shapePicker ||
          addedQuestionState.type === questionTypes.drawPolygon ||
          addedQuestionState.type === questionTypes.dropPin)
      ) {
        addedQuestionState.allCommunityLayers = allCommunityLayers.map(
          (layer: any) => ({
            ...layer,
            id: addedQuestionState.id + "-" + layer.id,
          })
        );

        dispatch(updateAddedQuestionState(addedQuestionState));
      };

      if (
        harvestPanelMapQuestions?.id
      ) {
        const { retrievalShapePicker,struckShapePicker } = harvestPanelMapQuestions;

        if(retrievalShapePicker?.allCommunityLayers?.length == 0){
          retrievalShapePicker.allCommunityLayers = allCommunityLayers.map(
            (layer: any) => ({ ...layer, id: retrievalShapePicker.id + "-" + layer.id })
          );
          harvestPanelMapQuestions.retrievalShapePicker = retrievalShapePicker;
        };

        if(struckShapePicker?.allCommunityLayers?.length == 0){
          struckShapePicker.allCommunityLayers = allCommunityLayers.map(
            (layer: any) => ({ ...layer, id: retrievalShapePicker.id + "-" + layer.id })
          );
          harvestPanelMapQuestions.struckShapePicker = struckShapePicker;
        };

        dispatch(updateAddedHarvestPanelMapQuestions(harvestPanelMapQuestions));
      };

      if(geographicalPanelMapQuestions?.id){
        if(geographicalPanelMapQuestions?.regionTypeShapePicker?.allCommunityLayers?.length ==0){
          const { regionTypeShapePicker } = geographicalPanelMapQuestions;

        regionTypeShapePicker.allCommunityLayers = allCommunityLayers.map(
          (layer: any) => ({ ...layer, id: regionTypeShapePicker.id + "-" + layer.id })
        );

        geographicalPanelMapQuestions.regionTypeShapePicker = regionTypeShapePicker;
        };

        if(geographicalPanelMapQuestions?.viewTypeShapePicker?.allCommunityLayers?.length == 0){
          const { viewTypeShapePicker } = geographicalPanelMapQuestions;

          viewTypeShapePicker.allCommunityLayers = allCommunityLayers.map(
            (layer: any) => ({ ...layer, id: viewTypeShapePicker.id + "-" + layer.id })
          );
  
          geographicalPanelMapQuestions.viewTypeShapePicker = viewTypeShapePicker;
        };

        dispatch(updateAddedGeographicalPanelMapQuestions(geographicalPanelMapQuestions));
      }
    };

    
  }, [allCommunityLayers, addedQuestionState, harvestPanelMapQuestions,geographicalPanelMapQuestions]);

  useEffect(()=>{
    if(allGlobalCommunityLayers && allGlobalCommunityLayers.length > 0){

      if (
        addedQuestionState &&
        addedQuestionState?.allGlobalCommunityLayers?.length == 0 
      ) {
        if(addedQuestionState.type === questionTypes.drawPolygon || addedQuestionState.type === questionTypes.dropPin){
          addedQuestionState.allGlobalCommunityLayers = allGlobalCommunityLayers?.filter((l:any)=>l?.type === "vector")
        }
        else{
          addedQuestionState.allGlobalCommunityLayers = allGlobalCommunityLayers
        }

        dispatch(updateAddedQuestionState(addedQuestionState));
      };

      if (
        harvestPanelMapQuestions?.id
      ) {
        const { retrievalShapePicker,struckShapePicker } = harvestPanelMapQuestions;

        if(retrievalShapePicker?.allGlobalCommunityLayers?.length === 0){
          retrievalShapePicker.allGlobalCommunityLayers = allGlobalCommunityLayers;
          harvestPanelMapQuestions.retrievalShapePicker = retrievalShapePicker;
        }

        if(struckShapePicker?.allGlobalCommunityLayers?.length === 0){
          struckShapePicker.allGlobalCommunityLayers = allGlobalCommunityLayers;
          harvestPanelMapQuestions.struckShapePicker = struckShapePicker;
        }

        dispatch(updateAddedHarvestPanelMapQuestions(harvestPanelMapQuestions));
      };

      if (
        harvestPanelMapQuestions?.id
      ) {
        const { retrievalPointPicker,struckPointPicker } = harvestPanelMapQuestions;

        if(retrievalPointPicker?.allGlobalCommunityLayers?.length === 0){
          retrievalPointPicker.allGlobalCommunityLayers = allGlobalCommunityLayers
          harvestPanelMapQuestions.retrievalPointPicker = retrievalPointPicker;
        };

        if(struckPointPicker?.allGlobalCommunityLayers?.length === 0){
          struckPointPicker.allGlobalCommunityLayers = allGlobalCommunityLayers
          harvestPanelMapQuestions.struckPointPicker = struckPointPicker;
        };

        dispatch(updateAddedHarvestPanelMapQuestions(harvestPanelMapQuestions));
      };

      if(geographicalPanelMapQuestions?.id){
        if(geographicalPanelMapQuestions?.regionTypeShapePicker?.allGlobalCommunityLayers?.length ==0){
          const { regionTypeShapePicker } = geographicalPanelMapQuestions;

        regionTypeShapePicker.allGlobalCommunityLayers = allGlobalCommunityLayers
        geographicalPanelMapQuestions.regionTypeShapePicker = regionTypeShapePicker;

        };

        if(geographicalPanelMapQuestions?.viewTypeShapePicker?.allGlobalCommunityLayers?.length == 0){
          const { viewTypeShapePicker } = geographicalPanelMapQuestions;

          viewTypeShapePicker.allGlobalCommunityLayers = allGlobalCommunityLayers
  
          geographicalPanelMapQuestions.viewTypeShapePicker = viewTypeShapePicker;
        };

        if (
          geographicalPanelMapQuestions?.pointPicker?.allGlobalCommunityLayers?.length == 0
        ) {
          const { pointPicker } = geographicalPanelMapQuestions;
  
          pointPicker.allGlobalCommunityLayers = allGlobalCommunityLayers
  
          geographicalPanelMapQuestions.pointPicker = pointPicker;
  
        };
        dispatch(updateAddedGeographicalPanelMapQuestions(geographicalPanelMapQuestions));
      }
    }
  },[allGlobalCommunityLayers,addedQuestionState, harvestPanelMapQuestions,geographicalPanelMapQuestions])

  useEffect(() => {
    if (allCommunityPoints && allCommunityPoints.length > 0) {
      if (
        addedQuestionState &&
        addedQuestionState.allCommunityMarkers?.length == 0 &&
        addedQuestionState.type === questionTypes.pointPicker
      ) {
        addedQuestionState.allCommunityMarkers = allCommunityPoints.map(
          (point: any) => ({
            ...point,
            id: addedQuestionState.id + "-" + point.id,
          })
        );

        dispatch(updateAddedQuestionState(addedQuestionState));
      };

      if (
        harvestPanelMapQuestions?.id
      ) {
        const { retrievalPointPicker,struckPointPicker } = harvestPanelMapQuestions;

        if(retrievalPointPicker?.allCommunityMarkers?.length == 0){
          retrievalPointPicker.allCommunityMarkers = allCommunityPoints.map(
            (point: any) => ({ ...point, id: retrievalPointPicker.id + "-" + point.id })
          );
          harvestPanelMapQuestions.retrievalPointPicker = retrievalPointPicker;
        };

        if(struckPointPicker?.allCommunityMarkers?.length == 0){
          struckPointPicker.allCommunityMarkers = allCommunityPoints.map(
            (point: any) => ({ ...point, id: struckPointPicker.id + "-" + point.id })
          );
          harvestPanelMapQuestions.struckPointPicker = struckPointPicker;
        };

        dispatch(updateAddedHarvestPanelMapQuestions(harvestPanelMapQuestions));
      };

      if (
        geographicalPanelMapQuestions &&
        geographicalPanelMapQuestions?.pointPicker?.allCommunityMarkers?.length == 0
      ) {
        const { pointPicker } = geographicalPanelMapQuestions;

        pointPicker.allCommunityMarkers = allCommunityPoints.map(
          (point: any) => ({ ...point, id: pointPicker.id + "-" + point.id })
        );

        geographicalPanelMapQuestions.pointPicker = pointPicker;

        dispatch(updateAddedGeographicalPanelMapQuestions(geographicalPanelMapQuestions));
      };
    };

  }, [allCommunityPoints, addedQuestionState, harvestPanelMapQuestions,geographicalPanelMapQuestions]);

  return null;
};

export { useAppendCommunityToCurrentQuestion };
