import MapboxDraw from "@mapbox/mapbox-gl-draw";
import {
  ADDED_GEOGRAPHICAL_PANEL_MAP_QUESTIONS_STATE,
  ADDED_HARVEST_PANEL_MAP_QUESTIONS_STATE,
  ADDED_QUESTION_STATE,
  DELETED_QUESTION_ELEMENT,
  SELECTED_QUESTION_ELEMENT,
  UPDATE_ALL_COMMUNITY_LAYERS,
  UPDATE_ALL_COMMUNITY_LAYERS_FETCHING_PROGRESS,
  UPDATE_ALL_COMMUNITY_POINTS,
  UPDATE_ALL_COMMUNITY_POINTS_FETCHING_PROGRESS,
  UPDATE_ALL_GLOBAL_COMMUNITY_LAYERS,
  UPDATE_ALL_GLOBAL_COMMUNITY_LAYERS_FETCHING_PROGRESS,
  UPDATE_CURRENT_QUESTION,
  UPDATE_DRAW_POLYGON,
  UPDATE_DRAW_POLYGONS,
  UPDATE_DROP_PIN,
  UPDATE_DROP_PINS,
  UPDATE_POINT_PICKER,
  UPDATE_POINT_PICKERS,
  UPDATE_SHAPE_PICKER,
  UPDATE_SHAPE_PICKERS,
  UPDATE_SURVEY_ACTIVE_TAB,
  UPDATE_GET_GPS_DATAS,
  DISPLAY_SURVEY_VIEW,
  DISPLAY_GEOGRAPHY_VIEW,
  UPDATE_SELECTED_LAYERS_ITEMS_DATA,
  UPDATE_CHOOSED_LAYERS_ITEMS_DATA,
  SET_FORM_JSON,
  SET_DRAW_SHAPE_MODE,
  UPDATE_DO_REMOVE_ALL_SHAPE_SIGNAL,
  SET_DRAW_SHAPE_GEOMETRY,
  DO_RESET_MAP,
  SET_DRAW_REFERENCE,
  SET_MAP,
  SET_FORM_ALL_SELECTED_SHAPES,
  SET_FORM_ALL_SELECTED_DEFAULT_LAYERS,
  SET_UPLOADED_LAYERS,
  REMOVE_UPLOADED_ITEM,
  SET_ACTIVE_AREA_SELECT_MODE,
  SET_IS_SHAPE_DRAWING_COMPLETED,
  SELECTED_UPLOADED_ITEM_TO_HIGHLIGHT,
  START_REFRESHING_DYNAMIC_FORM_GEOGRAPHIES,
  STOP_REFRESHING_DYNAMIC_FORM_GEOGRAPHIES,
} from "./action-types";
import { ActiveAreaSelectModeType } from "./initialState";

export const updateShapePicker = (payload: any) => {
  return {
    type: UPDATE_SHAPE_PICKER,
    payload,
  };
};

export const updateShapePickers = (payload: any) => {
  return {
    type: UPDATE_SHAPE_PICKERS,
    payload,
  };
};

export const updatePointPicker = (payload: any) => {
  return {
    type: UPDATE_POINT_PICKER,
    payload,
  };
};

export const updatePointPickers = (payload: any) => {
  return {
    type: UPDATE_POINT_PICKERS,
    payload,
  };
};

export const updateDrawPolygon = (payload: any) => {
  return {
    type: UPDATE_DRAW_POLYGON,
    payload,
  };
};

export const updateDrawPolygons = (payload: any) => {
  return {
    type: UPDATE_DRAW_POLYGONS,
    payload,
  };
};

export const updateDropPin = (payload: any) => {
  return {
    type: UPDATE_DROP_PIN,
    payload,
  };
};

export const updateDropPins = (payload: any) => {
  return {
    type: UPDATE_DROP_PINS,
    payload,
  };
};
export const updateGetGpsDatas = (payload: any) => {
  return {
    type: UPDATE_GET_GPS_DATAS,
    payload,
  };
};

export const updateDeletedQuestionEle = (payload: any) => {
  return {
    type: DELETED_QUESTION_ELEMENT,
    payload,
  };
};

export const updateSelectedQuestionEle = (payload: any) => {
  return {
    type: SELECTED_QUESTION_ELEMENT,
    payload,
  };
};

export const updateAddedQuestionState = (payload: any) => {
  return {
    type: ADDED_QUESTION_STATE,
    payload,
  };
};

export const updateAllCommunityLayers = (payload: any) => {
  return {
    type: UPDATE_ALL_COMMUNITY_LAYERS,
    payload,
  };
};

export const updateAllCommunityPoints = (payload: any) => {
  return {
    type: UPDATE_ALL_COMMUNITY_POINTS,
    payload,
  };
};

export const updateAllGlobalCommunityLayers = (payload: any) => {
  return {
    type: UPDATE_ALL_GLOBAL_COMMUNITY_LAYERS,
    payload,
  };
};

export const updateCurrentQuestion = (payload: any) => {
  return {
    type: UPDATE_CURRENT_QUESTION,
    payload,
  };
};

export const updateAddedHarvestPanelMapQuestions = (payload: any) => {
  return {
    type: ADDED_HARVEST_PANEL_MAP_QUESTIONS_STATE,
    payload,
  };
};

export const updateAddedGeographicalPanelMapQuestions = (payload: any) => {
  return {
    type: ADDED_GEOGRAPHICAL_PANEL_MAP_QUESTIONS_STATE,
    payload,
  };
};

export const updateAllCommunityLayersFetchingProgress = (payload: boolean) => {
  return {
    type: UPDATE_ALL_COMMUNITY_LAYERS_FETCHING_PROGRESS,
    payload,
  };
};
export const updateAllCommunityPointsFetchingProgress = (payload: boolean) => {
  return {
    type: UPDATE_ALL_COMMUNITY_POINTS_FETCHING_PROGRESS,
    payload,
  };
};
export const updateAllGlobalCommunityLayersFetchingProgress = (
  payload: boolean
) => {
  return {
    type: UPDATE_ALL_GLOBAL_COMMUNITY_LAYERS_FETCHING_PROGRESS,
    payload,
  };
};

export const updateSurveyActiveTab = (payload: string) => {
  return {
    type: UPDATE_SURVEY_ACTIVE_TAB,
    payload,
  };
};

export const displaySurveyView = () => {
  return {
    type: DISPLAY_SURVEY_VIEW,
  };
};

export const displayGeographyView = () => {
  return {
    type: DISPLAY_GEOGRAPHY_VIEW,
  };
};

export const updateSelectedLayersItems = (payload: any) => {
  return {
    type: UPDATE_SELECTED_LAYERS_ITEMS_DATA,
    payload,
  };
};

export const updateChoosedLayersItems = (payload: any) => {
  return {
    type: UPDATE_CHOOSED_LAYERS_ITEMS_DATA,
    payload,
  };
};

export const setFormJson = (payload: any) => {
  return {
    type: SET_FORM_JSON,
    payload,
  };
};

export const setDrawShapeMode = (
  payload?: "polygon" | "rectangle" | "circle" | "simple_select"
) => {
  return {
    type: SET_DRAW_SHAPE_MODE,
    payload,
  };
};

export const updateDoRemoveAllDrawnShapesSignal = (payload: any) => {
  return {
    type: UPDATE_DO_REMOVE_ALL_SHAPE_SIGNAL,
    payload,
  };
};

export const setDrawShapeGeometry = (payload: any) => {
  return {
    type: SET_DRAW_SHAPE_GEOMETRY,
    payload,
  };
};

export const setDoResetMap = (payload: boolean) => {
  return {
    type: DO_RESET_MAP,
    payload,
  };
};

export const setDrawReference = (payload: MapboxDraw) => {
  return {
    type: SET_DRAW_REFERENCE,
    payload,
  };
};

export const setMap = (payload: mapboxgl.Map) => {
  return {
    type: SET_MAP,
    payload,
  };
};

export const setFormAllSelectedShapes = (payload: any[]) => {
  return {
    type: SET_FORM_ALL_SELECTED_SHAPES,
    payload,
  };
};
export const setFormAllSelectedDefaultLayers = (payload: any[]) => {
  return {
    type: SET_FORM_ALL_SELECTED_DEFAULT_LAYERS,
    payload,
  };
};

export const setUploadedLayersAction = (layers: any[]) => {
  return {
    type: SET_UPLOADED_LAYERS,
    payload: layers,
  };
};

export const removeUploadedItemAction = (itemId: any) => {
  return {
    type: REMOVE_UPLOADED_ITEM,
    payload: itemId,
  };
};

export const setActiveAreaSelectMode = (payload: ActiveAreaSelectModeType) => {
  return {
    type: SET_ACTIVE_AREA_SELECT_MODE,
    payload,
  };
};

export const setIsShapeDrawingCompleted = (payload: boolean) => {
  return {
    type: SET_IS_SHAPE_DRAWING_COMPLETED,
    payload,
  };
};

export const selectedUploadedItemToHighlightAction = (payload: any) => {
  return {
    type: SELECTED_UPLOADED_ITEM_TO_HIGHLIGHT,
    payload,
  };
};

export const startRefreshingDynamicFormGeographiesAction = () => ({
  type: START_REFRESHING_DYNAMIC_FORM_GEOGRAPHIES,
});

export const stopRefreshingDynamicFormGeographiesAction = () => ({
  type: STOP_REFRESHING_DYNAMIC_FORM_GEOGRAPHIES,
});

// export const selectedUploadedItemToHighlightAction = (payload:any)=>{
//   return{
//     type:SELECTED_UPLOADED_ITEM_TO_HIGHLIGHT,
//     payload
//   }
// }
