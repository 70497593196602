import apiClient from "services/apiClient";

async function updateBasicReport(id: number, data: any) {
  const url = `/admin/reports/${id}/basic`;

  const res = await apiClient.put(url, data);

  return res.data;
}

export default updateBasicReport;
