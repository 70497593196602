import { CheveronDownIcon } from "assets/icons/HeroIcons";
import React, { useCallback, useEffect, useState } from "react";
import CustomCheckbox from "view/components/CheckBox";
import TextInput from "view/pages/MyProfile/Components/Inputs/TextInput";

import { AddPrograms } from "./AddPrograms";
import { AddCommunities } from "./AddCommunities";
import { AddForms } from "./AddForms";
import { AddOrganizations } from "./AddOrganizations";
import InformationCircleIcon from "assets/icons/HeroIcons/InformationCircleIcon";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
import usePermissions from "hooks/usePermissions";

interface IProps {
  handleChange: any;
  handleBlur: any;
  values: any;
  errors: any;
  touched: any;
  setFieldValue: any;
  setFieldTouched: any;
  reportSummary: any;
}

export const InputFields: React.FC<IProps> = ({
  handleChange,
  handleBlur,
  values,
  errors,
  touched,
  setFieldValue,
  reportSummary,
}) => {
  const [entities, setEntities] = useState({
    programs: [],
    communities: [],
    forms: [],
    organizations: [],
  });
  const { reports } = usePermissions();

  const convertDataForMultiselectDropDowns = (data: any) => {
    return data.map(({ id, name }: { id: number; name: string }) => ({
      value: id,
      label: name,
    }));
  };

  const getCommaSeparatedValues = (data: any[] = []) => {
    return data.map(({ value }) => value).join(",");
  };

  const fetchAllEntities = useCallback(
    async (
      communityIds: any[],
      organizationIds: any[],
      programIds: any[],
      formIds: any[],
      searchQuery = ""
    ) => {
      try {
        const queryParams: any = {
          searchString: searchQuery,
        };
        if (communityIds.length > 0) {
          queryParams.communityIds = getCommaSeparatedValues(communityIds);
        }
        if (organizationIds.length > 0) {
          queryParams.organizationIds =
            getCommaSeparatedValues(organizationIds);
        }
        if (programIds.length > 0) {
          queryParams.programIds = getCommaSeparatedValues(programIds);
        }
        if (formIds.length > 0) {
          queryParams.formIds = getCommaSeparatedValues(formIds);
        }
        if (searchQuery !== "") {
          queryParams.searchString = searchQuery;
        }
        const { data } = await apiLibrary.Reports.getReportsFilteringDropdowns(
          reportSummary.id,
          queryParams
        );
        const { forms, communities, programs, organizations } = data;

        setEntities((prev) => ({
          ...prev,
          communities:
            communityIds.length > 0
              ? prev.communities
              : convertDataForMultiselectDropDowns(communities.data),
          organizations:
            organizationIds.length > 0
              ? prev.organizations
              : convertDataForMultiselectDropDowns(organizations.data),
          programs:
            programIds.length > 0
              ? prev.programs
              : convertDataForMultiselectDropDowns(programs.data),
          forms:
            formIds.length > 0
              ? prev.forms
              : convertDataForMultiselectDropDowns(forms.data),
        }));
      } catch (error: any) {
        Toasts.error(error.message || "Failed to fetch data");
      }
    },
    [reportSummary.id]
  );

  useEffect(() => {
      fetchAllEntities(
        values.communityIds,
        values.organizationIds,
        values.programIds,
        values.formIds
      );
  }, [
values.communityIds,
    values.organizationIds,
    values.programIds,
    values.formIds,
    fetchAllEntities,
  ]);

  const handleEntitiesSearch = (searchString: any) => {
    fetchAllEntities(
      values.communityIds,
      values.organizationIds,
      values.programIds,
      values.formIds,
      searchString
    );
  };

  return (
    <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 dark:bg-secondaryLight">
      <div className="flex flex-col w-full py-1">
        <div className="flex flex-col w-full pb-7">
          <TextInput
            label="Report Name*"
            type="text"
            rows={3}
            placeholder="Report Name"
            name="name"
            handleChange={handleChange}
            handleBlur={handleBlur}
            value={values.name}
            error={errors?.name}
            touched={touched.name}
            inputClassName="w-full"
          />

          {!errors?.name && (
            <div className="absolute flex justify-start flex-grow-0 flex-shrink-0 top-20">
              <p className="flex-grow w-[608px] text-xs text-left text-textMidLight dark:text-textMain">
                255 max characters
              </p>
            </div>
          )}
        </div>
        {/* <CustomCheckbox
          className="w-auto"
          checked={values.filterByEntity}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const { checked } = event.target;

            if (!checked) {
              setFieldValue("programIds", []);
              setFieldValue("communityIds", []);
              setFieldValue("formIds", []);
              setFieldValue("organizationIds", []);
            }
            setFieldValue("filterByEntity", checked);
          }}
          label={ */}
        <div className="flex items-center justify-start flex-grow w-auto ">
          <div className="flex justify-start items-center flex-grow relative py-0.5">
            <p className="flex-grow w-auto  text-[15px] text-left text-secondaryMid  dark:text-textMain ">
              Filter by program, form, organization, community
            </p>
          </div>
        </div>
        {/* }
          labelPlacement="end"
        /> */}

        {reports.canViewEntityDropDownReports && (
          <>
            <AddPrograms
              handleAddProgramsOnChange={(options: any) => {
                setFieldValue("programIds", options);
              }}
              data={entities.programs}
              values={values}
              handleEntitiesSearch={handleEntitiesSearch}
              isMulti={true}
            />
            <AddForms
              handleAddFormsOnChange={(options: any) => {
                setFieldValue("formIds", options);
              }}
              values={values}
              data={entities.forms}
              handleEntitiesSearch={handleEntitiesSearch}
              isMulti={true}
            />
            <AddOrganizations
              handleAddOrganizationsOnChange={(options: any) => {
                setFieldValue("organizationIds", options);
              }}
              values={values}
              data={entities.organizations}
              handleEntitiesSearch={handleEntitiesSearch}
            />

            <AddCommunities
              handleAddCommunitiesOnChange={(options: any) => {
                setFieldValue("communityIds", options);
              }}
              values={values}
              data={entities.communities}
              handleEntitiesSearch={handleEntitiesSearch}
            />
            <div className="flex items-center gap-3">
              <InformationCircleIcon className="w-4 h-4" fill="#F2AA00" />
              <p className="text-sm text-secondaryMidLight dark:text-caption">
                If data from multiple forms and programs are combined in a
                report, processing time may be longer.
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
