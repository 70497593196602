import { CreateBtnProps } from "../../types";
import DetailedIcon from "assets/icons/HeroIcons/DetailedIcon";

const DetailedBtn = ({ handleClickToCreateReport }: CreateBtnProps) => {
  return (
    <button
      onClick={() => handleClickToCreateReport("detailed")}
      className="relative flex items-center self-stretch justify-center flex-grow-0 flex-shrink-0 gap-2 px-1 py-2 rounded-lg hover:bg-primary/[0.2]"
    >
      <DetailedIcon fill="black" />
      <div className="flex items-center justify-start flex-grow">
        <div className="flex justify-start items-center flex-grow relative py-0.5">
          <p className="flex-grow w-[132px] text-[15px] ml-[7px] text-left text-textMid">
            Detailed Report
          </p>
        </div>
      </div>
    </button>
  );
};

export { DetailedBtn };
