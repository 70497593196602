export interface ISummaryReports {
  allowExport: boolean;
  columnConfiguration: any[];
  communitiesFilter: any[];
  createdBy: {
    id: number;
    name: string;
  };
  dateCreated: string;
  dateFilter: DateFilter[];
  filterByEntity: boolean;
  filters: ReportsFilterSummary[];
  formsFilter: any[];
  geospatialFilter: SummaryGeospatial[];
  geospatialFilterType: string;
  id: number;
  organizationsFilter: any[];
  permissions: any[];
  programsFilter: any[];
  recurrenceSettings: string;
  reportName: string;
  reportSetting: SummaryReportSetting;
  repeatedDateFilter: RepeatDate;
  status: string;
  isCreator: boolean;
  type: string;
  programTimeRang: boolean;
  fieldsSelection: boolean;
}
interface RepeatDate {
  period: string;
  periodDuration: number;
  date: string;
  periodType: string;
  dateType: string;
}

interface ReportsFilterSummary {
  column: string;
  condition: string;
  id: string;
  operator: string;
  type: any;
  value: any;
}

interface DateFilter {
  condition: string;
  operator: "where" | "or" | "and";
  startDate?: string;
  endDate?: string;
  dateType: string;
  startTime?: string;
  endTime?: string;
}
interface SummaryReportSetting {
  endAfter: number | null;
  endOnDate: string;
  isRepeat: boolean;
  now: boolean;
  repeatDays: string[];
  repeatDaysCount: number;
  repeatPeriod: "" | "weeks" | "months" | "years";
  startRunDate: string;
  startRunTime: string;
  id: number;
  neverStop: boolean;
  dayOfWeek: number | null;
  weekOfMonth: number | null;
  dayOfMonth: number | null;
  type: string | null;
  monthlyOccurrenceTitle: string;
  isLastOccurrence: boolean;
}
interface SummaryGeospatial {
  filter: {
    column: string;
    condition: string;
    operator: string;
    region: any[];
    type: string;
  };
  region: {
    id: number;
    mapData: {
      features: [
        {
          geometry: {
            coordinates: any[];
          };
          properties: any;
          type: string;
        },
      ];
      type: string;
    };
    name: string;
  };
}
const initialReportsSummaryData: ISummaryReports = {
  allowExport: false,
  isCreator: false,
  status: "",
  columnConfiguration: [],
  communitiesFilter: [],
  createdBy: {
    id: 0,
    name: "",
  },
  dateCreated: "",
  dateFilter: [],
  filterByEntity: false,
  filters: [],
  formsFilter: [],
  geospatialFilter: [],
  geospatialFilterType: "",
  id: 0,
  organizationsFilter: [],
  permissions: [],
  programsFilter: [],
  recurrenceSettings: "",
  reportName: "",

  reportSetting: {
    endOnDate: "",
    endAfter: null,
    id: 0,
    isRepeat: false,
    now: false,
    repeatDays: [],
    repeatDaysCount: 0,
    repeatPeriod: "",
    startRunDate: "",
    startRunTime: "",
    dayOfWeek: null,
    weekOfMonth: null,
    dayOfMonth: null,
    type: null,
    neverStop: false,
    monthlyOccurrenceTitle: "",
    isLastOccurrence: false,
  },
  repeatedDateFilter: {
    period: "",
    periodDuration: 0,
    date: "",
    periodType: "",
    dateType: " ",
  },
  type: "",
  fieldsSelection: false,
  programTimeRang: false,
};

export { initialReportsSummaryData };
