import axios from "axios";
import usePermissions from "hooks/usePermissions";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import * as surveyJsApi from "services/api/surveyJS";
import { RootState } from "store";
import {
  updateAllCommunityPoints,
  updateAllCommunityPointsFetchingProgress,
} from "store/surveyJS";

const useFetchAllCommunityPoints = () => {
  const dispatch = useDispatch();
  const { formId } = useParams();

  const { allCommunityPoints } = useSelector(
    (state: RootState) => state.surveyJS
  );
  const { dynamicForms } = usePermissions();

  useEffect(() => {
    const fetchData = async () => {
      if (!formId || (allCommunityPoints && allCommunityPoints?.length != 0))
        return;

      dispatch(updateAllCommunityPointsFetchingProgress(false));
      try {
        if (!dynamicForms.canViewDynamicFormGeographies) {
          return;
        }
        const response = await surveyJsApi.default.fetchLayers(formId, "point");
        const updatedPoints = await appendMapDataToPointsIfTheyDontHaveMapData(
          response.data
        );
        dispatch(updateAllCommunityPoints(updatedPoints));
      } catch (err) {
        console.error("Error fetching all community points data:", err);
      } finally {
        dispatch(updateAllCommunityPointsFetchingProgress(true));
      }
    };

    fetchData();

    return () => {
      dispatch(updateAllCommunityPoints([]));
    };
  }, [formId]);

  return null;
};

export { useFetchAllCommunityPoints };

export async function appendMapDataToPointsIfTheyDontHaveMapData(data = []) {
  const promises = data.map(async (item: any) => {
    if (item.mapData === null && item.geoFilePath) {
      try {
        item.mapData = await fetchMapData(item.geoFilePath);
      } catch (error) {
        console.error("Error fetching map data for item:", error);
        item.mapData = null;
      }
    }
    return item;
  });

  return await Promise.all(promises);
}

async function fetchMapData(url: string) {
  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch map data:", error);
    return null;
  }
}
