import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { updateDrawPolygon, updateDrawPolygons } from "store/surveyJS";

import { useParams } from "react-router-dom";
import * as surveyJsApi from "services/api/surveyJS";
import {
  AbleToSelectMultiple,
  Description,
  MapCenterPoint,
  MapZoomLevel,
  Required,
  SelectLayers,
  Snapping,
  Title,
} from "./components";
import removeMatchedEleFromTwoArrays from "utils/removeMatchedEleFromTwoArrays";
import updateElementByIdFromArray from "../../utils/updateElementByIdFromArray";
import { Toasts } from "view/components/Toasts";
import _ from "lodash";
import { ManageLayersPropertiesModal } from "view/components/Modals/Forms/ManageLayersPropertiesModal";

interface OptionType {
  borderColor: string | null;
  color: string | null;
  geoFilePath: string;
  id: number | string;
  legendFileId: string | null;
  mapData: string | null;  
  name: string;
  shapeType?: string;
  type?: string;
  vectorLayers?: string | null;
  uniqueProps?: any;
}
const DrawPolygonSidebar = () => {
  const { drawPolygons, selectedQuestionElement } = useSelector(
    (state: RootState) => state.surveyJS
  );
  const [currentDrawPolygon, setCurrentDrawPolygon] = useState<any>({});

  const dispatch = useDispatch();

  // structuring data of default points according to dropdown component
  const defaultLayersSelectedOptions = currentDrawPolygon.selectedDefaultLayers;

  const defaultLayersOptions = _.differenceBy(
    currentDrawPolygon.allGlobalCommunityLayers,
    currentDrawPolygon.selectedDefaultLayers,
    "id"
  );

  useEffect(() => {
    if (selectedQuestionElement) {
      selectedQuestionElement.onPropertyChanged.add(
        (sender: any, options: any) => {
          if (options.name == "title") {
            handleTitleOnChange(options.newValue);
          } else if (options.name == "description") {
            handleDescriptionOnChange(options.newValue);
          }
        }
      );
    }
  }, [selectedQuestionElement, currentDrawPolygon]);

  // recognize and set current point picker
  useEffect(() => {
    const questionId = selectedQuestionElement._id;
    if (questionId) {
      const ele = drawPolygons.find((s: any) => s.id === questionId);

      if (ele) {
        const title = selectedQuestionElement.propertyHash.title ?? ele.title;
        const description =
          selectedQuestionElement.propertyHash.description ?? ele.description;

        setCurrentDrawPolygon({ ...ele, title, description });
      }
    }
  }, [selectedQuestionElement, drawPolygons]);

  // handlers
  const handleMapInputsOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    // update current element
    const updatedCurrentDrawPolygon = { ...currentDrawPolygon };

    if (name === "zoomLevel") {
      updatedCurrentDrawPolygon.zoomLevel = parseFloat(value);
    } else {
      updatedCurrentDrawPolygon.mapCenterPoint = {
        ...updatedCurrentDrawPolygon.mapCenterPoint,
        doFly: false,
        [name]: value,
      };
    }

    // update current element state into global elements
    updateElementByIdFromArray(
      drawPolygons,
      updatedCurrentDrawPolygon,
      (updatedDrawPolygons: any) => {
        setCurrentDrawPolygon(updatedCurrentDrawPolygon);
        dispatch(updateDrawPolygons(updatedDrawPolygons));
      }
    );
  };
  const handleClickOnZoomLevelBtn = () => {
    const updatedCurrentDrawPolygon = { ...currentDrawPolygon };
    if (isNaN(updatedCurrentDrawPolygon.zoomLevel)) {
      return;
    }
    // update current element
    updatedCurrentDrawPolygon.mapCenterPoint = {
      ...updatedCurrentDrawPolygon.mapCenterPoint,
      ...updatedCurrentDrawPolygon.zoomLevel,

      doFly: true,
    };

    // update current element state into global elements
    updateElementByIdFromArray(
      drawPolygons,
      updatedCurrentDrawPolygon,
      (updatedDrawPolygons: any) => {
        setCurrentDrawPolygon(updatedCurrentDrawPolygon);
        dispatch(updateDrawPolygons(updatedDrawPolygons));
      }
    );
  };
  const handleClickOnLocationNavigatorButton = () => {
    const updatedCurrentDrawPolygon = { ...currentDrawPolygon };

    // update current element
    updatedCurrentDrawPolygon.mapCenterPoint = {
      ...updatedCurrentDrawPolygon.mapCenterPoint,
      doFly: true,
    };

    // update current element state into global elements
    updateElementByIdFromArray(
      drawPolygons,
      updatedCurrentDrawPolygon,
      (updatedDrawPolygons: any) => {
        setCurrentDrawPolygon(updatedCurrentDrawPolygon);
        dispatch(updateDrawPolygons(updatedDrawPolygons));
      }
    );
  };
  const handleAbleToSelectMultipleCheckboxOnChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = e.target.checked;

    // update current element
    const updatedCurrentDrawPolygon = { ...currentDrawPolygon };

    updatedCurrentDrawPolygon.ableToCheckMultiple = isChecked;

    // update current element state into global elements
    updateElementByIdFromArray(
      drawPolygons,
      updatedCurrentDrawPolygon,
      (updatedDrawPolygons: any) => {
        setCurrentDrawPolygon(updatedCurrentDrawPolygon);
        dispatch(updateDrawPolygons(updatedDrawPolygons));
      }
    );
  };
  const handleEnableSnappingCheckboxOnChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = e.target.checked;

    // update current element
    const updatedCurrentDrawPolygon = { ...currentDrawPolygon };

    updatedCurrentDrawPolygon.enableSnapping = isChecked;

    // update current element state into global elements
    updateElementByIdFromArray(
      drawPolygons,
      updatedCurrentDrawPolygon,
      (updatedDrawPolygons: any) => {
        setCurrentDrawPolygon(updatedCurrentDrawPolygon);
        dispatch(updateDrawPolygons(updatedDrawPolygons));
      }
    );
  };
  const handleDefaultLayersOnSelection = (option: OptionType) => {
    const { selectedDefaultLayers } = currentDrawPolygon;

    // Check if the layer already exists
    const existingIndex = selectedDefaultLayers.findIndex(
      (item: OptionType) => item.id === option.id
    );

    let updatedSelectedDefaultLayers;

    if (existingIndex !== -1) {
      // Replace the existing layer with the new option
      updatedSelectedDefaultLayers = [
        ...selectedDefaultLayers.slice(0, existingIndex),
        option,
        ...selectedDefaultLayers.slice(existingIndex + 1),
      ];
    } else {
      // Add the new option since it doesn't exist
      updatedSelectedDefaultLayers = [...selectedDefaultLayers, option];
    }

    // Update the currentDrawPolygon with new or replaced layers
    const updatedCurrentDrawPolygon = {
      ...currentDrawPolygon,
      selectedDefaultLayers: updatedSelectedDefaultLayers,
    };

    // Update current element state into global elements
    updateElementByIdFromArray(
      drawPolygons,
      updatedCurrentDrawPolygon,
      (updatedDrawPolygons: any) => {
        setCurrentDrawPolygon(updatedCurrentDrawPolygon);
        dispatch(updateDrawPolygons(updatedDrawPolygons));
      }
    );
  };

  const handleDefaultLayersOnRemove = (option: any) => {
    // update current element
    const updatedCurrentDrawPolygon = { ...currentDrawPolygon };

    updatedCurrentDrawPolygon.selectedDefaultLayers =
      currentDrawPolygon.selectedDefaultLayers.filter(
        (l: any) => l.id !== option.id
      );

    // update current element state into global elements
    updateElementByIdFromArray(
      drawPolygons,
      updatedCurrentDrawPolygon,
      (updatedDrawPolygons: any) => {
        setCurrentDrawPolygon(updatedCurrentDrawPolygon);
        dispatch(updateDrawPolygons(updatedDrawPolygons));
      }
    );
  };
  const handleTitleOnChange = (value: string) => {
    // update current element
    const updatedCurrentDrawPolygon = { ...currentDrawPolygon };

    updatedCurrentDrawPolygon.title = value;

    if (selectedQuestionElement) {
      selectedQuestionElement.title = value;
    }

    // update current element state into global elements
    updateElementByIdFromArray(
      drawPolygons,
      updatedCurrentDrawPolygon,
      (updatedDrawPolygons: any) => {
        setCurrentDrawPolygon(updatedCurrentDrawPolygon);
        dispatch(updateDrawPolygons(updatedDrawPolygons));
      }
    );
  };
  const handleDescriptionOnChange = (value: string) => {
    // update current element
    const updatedCurrentDrawPolygon = { ...currentDrawPolygon };

    updatedCurrentDrawPolygon.description = value;

    if (selectedQuestionElement) {
      selectedQuestionElement.description = value;
    }

    // update current element state into global elements
    updateElementByIdFromArray(
      drawPolygons,
      updatedCurrentDrawPolygon,
      (updatedDrawPolygons: any) => {
        setCurrentDrawPolygon(updatedCurrentDrawPolygon);
        dispatch(updateDrawPolygons(updatedDrawPolygons));
      }
    );
  };
  const handleIsRequiredCheckboxOnChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = e.target.checked;

    // update current element
    const updatedCurrentDrawPolygon = { ...currentDrawPolygon };

    updatedCurrentDrawPolygon.isRequired = isChecked;

    if (selectedQuestionElement) {
      selectedQuestionElement.isRequired = isChecked;
    }

    // update current element state into global elements
    updateElementByIdFromArray(
      drawPolygons,
      updatedCurrentDrawPolygon,
      (updatedDrawPolygons: any) => {
        setCurrentDrawPolygon(updatedCurrentDrawPolygon);
        dispatch(updateDrawPolygons(updatedDrawPolygons));
      }
    );
  };


  return (
    <div className="flex flex-col py-[5px] px-[32px] bg-[#F3F5F7]  w-full h-full gap-8">
      <Title
        handleTitleOnChange={handleTitleOnChange}
        value={currentDrawPolygon.title}
      />
      <Description
        handleDescriptionOnChange={handleDescriptionOnChange}
        value={currentDrawPolygon.description}
      />
      <Required
        handleCheckboxOnChange={handleIsRequiredCheckboxOnChange}
        checked={currentDrawPolygon.isRequired}
      />
      <MapCenterPoint
        handleInputsOnChange={handleMapInputsOnChange}
        handleClickOnLocationNavigatorButton={
          handleClickOnLocationNavigatorButton
        }
        mapCenterPoint={currentDrawPolygon.mapCenterPoint}
      />
      <MapZoomLevel
        handleInputsOnChange={handleMapInputsOnChange}
        handleClickOnZoomLevelBtn={handleClickOnZoomLevelBtn}
        zoomLevel={currentDrawPolygon.zoomLevel}
      />
      <div>
        <AbleToSelectMultiple
          handleCheckboxOnChange={handleAbleToSelectMultipleCheckboxOnChange}
          ableToCheckMultiple={currentDrawPolygon.ableToCheckMultiple}
        />
        <Snapping
          enableSnapping={currentDrawPolygon.enableSnapping}
          handleCheckboxOnChange={handleEnableSnappingCheckboxOnChange}
        />
      </div>
      <SelectLayers
        handleSelectorOnChange={handleDefaultLayersOnSelection}
        handleLayerOnRemove={handleDefaultLayersOnRemove}
        selectedOptions={defaultLayersSelectedOptions}
        options={defaultLayersOptions}
      />
    </div>
  );
};

export { DrawPolygonSidebar };
