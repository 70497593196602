import { useFormikContext } from "formik";
import { RadioGroup } from "@mui/material";
import { RadioBtn } from "Components/RadioBtn";
import TextInput from "view/pages/MyProfile/Components/Inputs/TextInput";
import { IReportSettingsFormValues } from "../..";
import InputField from "view/components/InputField";
import moment from "moment";
import { DATE_FORMATS } from "constants/dateFormats";


export const EndsSettings: React.FC = ({}) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    setFieldError,
  } = useFormikContext<IReportSettingsFormValues>();

  const currentDate = moment().format(DATE_FORMATS.DEFAULT);

  return (
    <RadioGroup
      aria-labelledby="demo-radio-buttons-group-label"
      defaultValue={values.endAfter ? "after" : "on"}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedValue = e.target.value;

        if (selectedValue === "on") {
          setFieldValue("endAfter", "");
          setFieldValue("endOnDateIsSelected", true);
          setFieldValue("endAfterIsSelected", false);
          // setFieldValue("neverStop", false);
        } else if (selectedValue === "after") {
          setFieldValue("endOnDate", "");
          setFieldValue("endOnDateIsSelected", false);
          setFieldValue("endAfterIsSelected", true);
          // setFieldValue("neverStop", false);
        }
        //  else if (selectedValue === "neverStop") {
        //   setFieldValue("endOnDate", "");
        //   setFieldValue("endOnDateIsSelected", false);
        //   setFieldValue("endAfterIsSelected", false);
        //   setFieldValue("endAfter", "");
        //   setFieldValue("neverStop", true);
        //   setFieldError("endAfter", "");
        //   setFieldError("endOnDate", "");
        // }
      }}
    >
      <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 dark:bg-secondaryLight">
        <div className="flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 pr-3 rounded-lg">
          <div className="flex items-center justify-start flex-grow">
            <div className="relative flex items-center justify-start flex-grow">
              <p className="text-base font-semibold text-left text-textMid dark:text-textMain">
                Ends
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full">
          <div className="flex items-center justify-start flex-grow w-full pl-1 pr-3 rounded-lg">
            <div className="flex items-center justify-center flex-grow-0 flex-shrink-0">
              <div className="flex  justify-center items-center px-2 rounded-[100px]">
                {/* <RadioBtn
                  checked={values.neverStop}
                  value="neverStop"
                  labelContent={
                    <span
                      style={{
                        fontSize: "15px",
                        fontWeight: "400",
                        color: "#2C3236",
                      }}
                    >
                      <div className="flex items-center w-full pl-2">
                        <p className="text-sm text-left capitalize text-textMid dark:text-textMain">
                          Never
                        </p>
                      </div>
                    </span>
                  }
                /> */}
              </div>
            </div>
          </div>

          <div className="flex items-center justify-start flex-grow w-full pl-1 pr-3 rounded-lg">
            <div className="flex items-center justify-center flex-grow-0 flex-shrink-0">
              <div className="flex  justify-center items-center px-2 rounded-[100px]">
                <RadioBtn
                  value="on"
                  checked={values.endOnDateIsSelected}
                  labelContent={
                    <span
                      style={{
                        fontSize: "15px",
                        fontWeight: "400",
                        color: "#2C3236",
                      }}
                    >
                      <div className="flex items-center w-full pl-2">
                        <p className="text-sm text-left capitalize text-textMid dark:text-textMain">
                          On
                        </p>
                        <div className="w-[296px] ml-12">
                          <InputField
                            label=""
                            type="date"
                            name="endOnDate"
                            onChange={(values: any) =>
                              setFieldValue("endOnDate", values)
                            }
                            onBlur={handleBlur}
                            value={values?.endOnDate}
                            error={errors?.endOnDate}
                            touched={touched.endOnDate}
                            min={
                              values.startRunDate !== ""
                                ? values.startRunDate
                                : currentDate
                            }
                            inputClassName="uppercase"
                            disabled={
                              values.endAfterIsSelected
                            }
                            className={"w-full"}
                          />
                        </div>
                      </div>
                    </span>
                  }
                />
              </div>
            </div>
          </div>
          <div className="flex items-center justify-start flex-grow w-full pl-1 pr-3 rounded-lg">
            <div className="flex items-center justify-center flex-grow-0 flex-shrink-0">
              <div className="flex justify-center items-center px-2 rounded-[100px]">
                <RadioBtn
                  value="after"
                  checked={values.endAfterIsSelected}
                  labelContent={
                    <span
                      style={{
                        fontSize: "15px",
                        fontWeight: "400",
                        color: "#2C3236",
                      }}
                    >
                      <div className="flex items-center justify-start w-full pl-2">
                        <p className="text-sm text-left textMid dark:text-textMain">
                          After
                        </p>

                        <div className="w-[296px] ml-9 mr-3">
                          <TextInput
                            height={38}
                            label=""
                            type="number"
                            name="endAfter"
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            value={values?.endAfter}
                            error={errors?.endAfter}
                            touched={touched.endAfter}
                            disabled={
                              values.endOnDateIsSelected
                            }
                          />
                        </div>
                        <p className="text-sm text-left textMid dark:text-textMain">
                          times
                        </p>
                      </div>
                    </span>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </RadioGroup>
  );
};
