import { SurveyQuestionElementBase } from "survey-react-ui";

import Map from "./Map";
import store from "store";
import { TailSpin } from "react-loader-spinner";
import "mapbox-gl/dist/mapbox-gl.css";

export const pointPickerProperties = [{
  name: "_id",
  category: "general",
  visibleIndex: 2,
}];
export default class SurveyPointPickerQuestion extends SurveyQuestionElementBase {
  constructor(props: any) {
    super(props);

    this.state = { value: this.question.value };
  }

  get question() {
    return this.questionBase;
  }

  get value() {
    return this.question.value;
  }

  renderElement() {
    // @ts-ignore
    const isRefreshingGeographies =
    store.getState()?.surveyJS?.isRefreshingGeographies ?? false;
  //@ts-ignore

  if (isRefreshingGeographies) {
    return <div className="loader h-[400px] justify-center items-center flex w-full">
    <TailSpin
      height="50"
      width="50"
      color="#005C89"
      ariaLabel="tail-spin-loading"
      radius="2"
      wrapperStyle={{}}
      wrapperClass="tailspin-loader"
      visible={true}
    />
  </div>;
  } else {
    return (
      <>
        <Map questionId={this.question._id} name={this.question.name} />
      </>
    );
  }  }
}
