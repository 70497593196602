import { CreateBtnProps } from "../../types";
import BarIcon from "assets/icons/HeroIcons/BarIcon";

const BasicBtn = ({ handleClickToCreateReport }: CreateBtnProps) => {
  return (
    <button
      onClick={() =>handleClickToCreateReport('basic')}
      className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2 px-1 rounded-lg hover:bg-primary/[0.2]"
    >
      <span className="mt-4 ml-1">
        <BarIcon fill="black" />
      </span>
      <div className="flex items-center justify-start flex-grow">
        <div className="flex justify-start items-center flex-grow relative">
          <p className="flex-grow w-[132px] text-[15px] text-left text-textMid">
            Basic Report
          </p>
        </div>
      </div>
    </button>
  );
};

export { BasicBtn };
