import { useFormikContext } from "formik";
import { RadioGroup } from "@mui/material";
import { RadioBtn } from "Components/RadioBtn";
import { IReportSettingsFormValues } from "../..";

export const ReportTimeSelection: React.FC = () => {
  const { values, setFieldValue, resetForm, errors } =
    useFormikContext<IReportSettingsFormValues>();

  return (
    <>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        value={values.now ? "now" : "pickDate"}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          const { value } = event.target;
          setFieldValue("now", value === "now");
          if (value === "now") {
            setFieldValue("endAfter", null);
            setFieldValue("endOnDate", "");
            setFieldValue("isRepeat", false);
            setFieldValue("now", true);
            setFieldValue("repeatDays", []);
            setFieldValue("repeatDaysCount", 0);
            setFieldValue("repeatPeriod", "weeks");
            setFieldValue("startRunDate", "");
            setFieldValue("startRunTime", "");
            setFieldValue("neverStop", false);
            setFieldValue("endAfterIsSelected", false);
            setFieldValue("endOnDateIsSelected", true);
          }
        }}
      >
        <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 dark:bg-secondaryLight">
          <div className="flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 pr-3 rounded-lg">
            <div className="flex items-center justify-start flex-grow py-2">
              <div className="relative flex items-center justify-start flex-grow">
                <p className="text-base font-semibold text-left text-textMid dark:text-textMain">
                  When to run report
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full">
            <div className="flex items-center justify-start flex-grow w-full pl-1 pr-3 rounded-lg">
              <div className="flex items-center justify-center flex-grow-0 flex-shrink-0">
                <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative px-2 rounded-[100px]">
                  <RadioBtn
                    value="now"
                    labelContent={
                      <span
                        style={{
                          fontSize: "15px",
                          fontWeight: "400",
                          color: "#2C3236",
                        }}
                      >
                        <div className="flex items-center justify-start flex-grow pl-2">
                          <div className="flex flex-col justify-start items-start flex-grow relative gap-0.5">
                            <p className="text-sm text-left capitalize text-textMid dark:text-textMain">
                              Now
                            </p>
                          </div>
                        </div>
                      </span>
                    }
                  />
                </div>
              </div>
            </div>
            <div className="flex items-center justify-start flex-grow w-full pl-1 pr-3 rounded-lg">
              <div className="flex items-center justify-center flex-grow-0 flex-shrink-0">
                <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative px-2 rounded-[100px]">
                  <RadioBtn
                    value="pickDate"
                    labelContent={
                      <span
                        style={{
                          fontSize: "15px",
                          fontWeight: "400",
                          color: "#2C3236",
                        }}
                      >
                        <div className="flex items-center justify-start flex-grow pl-2">
                          <div className="flex flex-col justify-start items-start flex-grow relative gap-0.5 ">
                            <p className="text-sm text-left textMid dark:text-textMain">
                              Pick date
                            </p>
                          </div>
                        </div>
                      </span>
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </RadioGroup>
    </>
  );
};
