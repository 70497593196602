import React, { useCallback, useEffect, useState } from "react";
import CustomCheckbox from "view/components/CheckBox";
import TextInput from "view/pages/MyProfile/Components/Inputs/TextInput";

import { AddPrograms } from "./AddPrograms";
import { AddForms } from "./AddForms";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
import usePermissions from "hooks/usePermissions";
import InputField from "view/components/InputField";
import moment from "moment";

interface IProps {
  handleChange: any;
  handleBlur: any;
  values: any;
  errors: any;
  touched: any;
  setFieldValue: any;
  setFieldTouched: any;
  reportSummary: any;
  formInitialValuesForBasicReports: any;
  setTouched: any;
  setErrors: any;
  setFutureDate: any;
  futureDate: any;
}

export const BasicReportFields: React.FC<IProps> = ({
  handleChange,
  handleBlur,
  values,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
  reportSummary,
  formInitialValuesForBasicReports,
  setTouched,
  setErrors,
  setFutureDate,
  futureDate,
}) => {
  const [entities, setEntities] = useState({
    programs: [],
    forms: [],
  });

  const [programData, setProgramData] = useState<any>();
  const [timeRange, setTimeRange] = useState<any>();
  const { reports } = usePermissions();
  const [currentDate, setCurrentDate] = useState<any>();

  const convertDataForMultiselectDropDowns = (data: any) => {
    return data.map(({ id, name }: { id: number; name: string }) => ({
      value: id,
      label: name,
    }));
  };

  const fetchAllEntities = useCallback(
    async (programIds: any, formIds: any, searchQuery = "") => {
      try {
        const queryParams: any = {
          searchString: searchQuery,
        };

        // If programIds has values, extract the "value" from each object
        if (programIds) {
          queryParams.programIds = programIds.value;
        }

        if (searchQuery !== "") {
          queryParams.searchString = searchQuery;
        }

        const { data } = await apiLibrary.Reports.getReportsFilteringDropdowns(
          reportSummary.id,
          queryParams
        );

        const { forms, programs } = data;

        setEntities((prev) => ({
          ...prev,
          programs: programIds
            ? prev.programs
            : convertDataForMultiselectDropDowns(programs.data),
          forms: formIds
            ? prev.forms
            : convertDataForMultiselectDropDowns(forms.data),
        }));
      } catch (error: any) {
        Toasts.error(error.message || "Failed to fetch data");
      }
    },
    [reportSummary.id]
  );

  useEffect(() => {
    fetchAllEntities(values.programIds, values.formIds);
  }, [values.programIds, values.formIds, fetchAllEntities]);

  const handleEntitiesSearch = (searchString: any) => {
    fetchAllEntities(values.programIds, values.formIds, searchString);
  };

  // to get the program start and end date on every render
  useEffect(() => {
    // set the current date
    const today = moment().format("YYYY-MM-DD");
    setCurrentDate(today);

    if (programData || values.programIds?.value) {
      handleDateOnProgram(programData || values.programIds);
    }
  }, [values.programTimeRang, programData]);
  // fetch the program data
  const handleDateOnProgram = async (data: any) => {
    try {
      const timeData = await apiLibrary.Programs.getProgramTimeRange(
        data?.value
      );
      setTimeRange(timeData?.data);
    } catch {}
  };
  useEffect(() => {
    if (timeRange?.startDate && currentDate) {
      const startDate = moment(timeRange.startDate);
      const today = moment(currentDate);

      if (startDate.isAfter(today, "day")) {
        setFutureDate(true);
      } else {
        setFutureDate(false); // Reset the flag if it's not a future date
      }
    }
  }, [timeRange, currentDate]);
  return (
    <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 dark:bg-secondaryLight">
      <div className="flex flex-col w-full py-1">
        <div className="flex flex-col w-full py-1">
          <TextInput
            label="Report Name*"
            type="text"
            rows={3}
            placeholder="Report Name"
            name="name"
            handleChange={handleChange}
            handleBlur={handleBlur}
            value={values.name}
            error={errors?.name}
            touched={touched.name}
            inputClassName="w-full"
          />

          {!errors?.name && (
            <div className="absolute flex justify-start flex-grow-0 flex-shrink-0 top-20">
              <p className="flex-grow w-[608px] text-xs text-left text-textMidLight dark:text-textMain">
                255 max characters
              </p>
            </div>
          )}
        </div>

        {reports.canViewEntityDropDownReports && (
          <>
            <div className="py-2">
              <div className="relative py-1">
                <AddPrograms
                  handleAddProgramsOnChange={(options: any) => {
                    setFieldValue("programIds", options);
                    setFieldValue("formIds", "");
                    setProgramData(options);
                  }}
                  data={entities.programs}
                  values={values}
                  handleEntitiesSearch={handleEntitiesSearch}
                  isMulti={false}
                  errors={values.programIds ? "": errors}
                  futureDate={futureDate}
                />
              </div>

              <div className={`relative`}>
                <AddForms
                  handleAddFormsOnChange={(options: any) => {
                    setFieldValue("formIds", options);
                  }}
                  values={values}
                  data={entities.forms}
                  handleEntitiesSearch={handleEntitiesSearch}
                  isMulti={false}
                  errors={errors}
                />
              </div>
            </div>
            <div className="py-2">
              <p className="text-base font-medium">Time Range</p>
              <div className="flex items-center gap-3">
                <CustomCheckbox
                  className="w-auto"
                  checked={values.programTimeRang}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const { checked } = event.target;
                    if (checked) {
                      const defaultEndDate = moment().format("YYYY-MM-DD");
                      const defaultEndTime = moment().format("HH:mm:ss");

                      setFieldValue("startDate", timeRange?.startDate);
                      setFieldValue("startTime", timeRange?.startTime);
                      setFieldValue(
                        "endDate",
                        timeRange?.endDate || defaultEndDate
                      );
                      setFieldValue(
                        "endTime",
                        timeRange?.endTime || defaultEndTime
                      );
                    }
                    setFieldValue("programTimeRang", checked);
                  }}
                  label={"Use time range of program"}
                  labelPlacement="end"
                />
              </div>
            </div>

            <div className="flex w-full gap-10 ">
              <div className="flex gap-5 items-center relative w-2/5">
                <div className="">
                  <p className="w-28">Start Date</p>
                </div>
                <InputField
                  label=""
                  type="date"
                  // height={38}
                  className={"w-full relative"}
                  min={""}
                  max={currentDate}
                  name={"startDate"}
                  onChange={(values: any) => {
                    const defaultStartTime = moment().format("HH:mm:ss");
                    if (values !== timeRange?.startDate) {
                      setFieldValue("programTimeRang", false);
                    }
                    setFieldValue("startDate", values);
                    setFieldValue("startTime", defaultStartTime);
                  }}
                  error={values.startDate ? "" : errors?.startDate}
                  value={values?.startDate}
                  touched={touched?.startDate}
                  onBlur={handleBlur}
                />
              </div>
              <div className="flex gap-5 items-center w-2/5">
                <div>
                  <p className="w-28">End Date</p>
                </div>
                <InputField
                  label=""
                  type="date"
                  // height={38}
                  className={"w-full"}
                  min={values?.startDate}
                  max={""}
                  name={"endDate"}
                  onChange={(values: any) => {
                    const defalutEndTime = moment().format("HH:mm:ss");
                    if (values !== timeRange?.endDate) {
                      setFieldValue("programTimeRang", false);
                    }
                    setFieldValue("endDate", values);
                    setFieldValue("endTime", defalutEndTime);
                  }}
                  error={""}
                  value={values?.endDate}
                  touched={""}
                  onBlur={handleBlur}
                />
              </div>
            </div>

            <div className="flex items-center gap-3 py-3">
              <CustomCheckbox
                className="w-auto"
                checked={values.fieldsSelection}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const { checked } = event.target;
                  setFieldValue("fieldsSelection", checked);
                }}
                label={
                  "I want to configure what data fields I want to include in this report."
                }
                labelPlacement="end"
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
