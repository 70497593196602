import questionTypes from "./questionTypes";
import generateUniqueId from "./utils/generateUniqueId";

class ShapePickerState {
  [x: string]: any;

  constructor(data?: any) {
   
    this.id = data?._id
      ? data?._id
      : data?.id ?? generateUniqueId("shape-picker#");
    this.type = questionTypes.shapePicker;
    this.title = data?.title;
    this.description = data?.description ?? "";
    this.isRequired = data?.isRequired ?? false;
    this.allCommunityLayers = data?.allCommunityLayers ?? [];
    this.allGlobalCommunityLayers = data?.allGlobalCommunityLayers ?? [];
    this.selectedLayersIdToHighlight = data?.selectedLayersIdToHighlight ?? [];
    this.mapCenterPoint = data?.mapCenterPoint ?? {
      longitude: "",
      latitude: "",
    };
    this.zoomLevel = data?.zoomLevel ?? 2;
    this.ableToCheckMultiple = data?.ableToCheckMultiple ?? false;
    this.selectedDefaultLayers = data?.selectedDefaultLayers ?? [];
  }

  getState = () => {
    return {
      id: this.id,
      type: this.type,
      title: this.title,
      description: this.description,
      isRequired: this.isRequired,
      allCommunityLayers: this.allCommunityLayers,
      allGlobalCommunityLayers: this.allGlobalCommunityLayers,
      selectedLayersIdToHighlight: this.selectedLayersIdToHighlight,
      mapCenterPoint: this.mapCenterPoint,
      zoomLevel: this.zoomLevel,
      ableToCheckMultiple: this.ableToCheckMultiple,
      selectedDefaultLayers: this.selectedDefaultLayers,
    };
  };
}
class PointPickerState {
  [x: string]: any;

  constructor(data?: any) {
    this.id = data?._id ?? data?.id ?? generateUniqueId("point-picker#");
    this.type = questionTypes.pointPicker;
    this.title = data?.title;
    this.description = data?.description ?? "";
    this.isRequired = data?.isRequired ?? false;
    this.allCommunityMarkers = data?.allCommunityMarkers ?? [];
    this.allGlobalCommunityLayers = data?.allGlobalCommunityLayers ?? [];
    this.selectedPointsIdToHighlight = data?.selectedPointsIdToHighlight ?? [];
    this.mapCenterPoint = data?.mapCenterPoint ?? {
      longitude: "",
      latitude: "",
    };
    this.zoomLevel = data?.zoomLevel ?? 2;
    this.ableToCheckMultiple = data?.ableToCheckMultiple ?? false;
    this.selectedDefaultLayers = data?.selectedDefaultLayers ?? [];
  }

  getState = () => {
    return {
      id: this.id,
      type: this.type,
      title: this.title,
      description: this.description,
      isRequired: this.isRequired,
      allCommunityMarkers: this.allCommunityMarkers,
      allGlobalCommunityLayers: this.allGlobalCommunityLayers,
      selectedPointsIdToHighlight: this.selectedPointsIdToHighlight,
      mapCenterPoint: this.mapCenterPoint,
      zoomLevel: this.zoomLevel,
      ableToCheckMultiple: this.ableToCheckMultiple,
      selectedDefaultLayers: this.selectedDefaultLayers,
    };
  };
}
class DrawPolygonState {
  [x: string]: any;

  constructor(data?: any) {
    this.id = data?._id
      ? data?._id
      : data?.id ?? generateUniqueId("draw-polygon#");
    this.type = questionTypes.drawPolygon;
    this.title = data?.title;
    this.description = data?.description ?? "";
    this.isRequired = data?.isRequired ?? false;
    this.allCommunityLayers = data?.allCommunityLayers ?? [];
    this.allGlobalCommunityLayers = data?.allGlobalCommunityLayers ?? [];
    this.mapCenterPoint = data?.mapCenterPoint ?? {
      longitude: "",
      latitude: "",
    };
    this.zoomLevel = data?.zoomLevel ?? 2;
    this.ableToCheckMultiple = data?.ableToCheckMultiple ?? false;
    this.selectedDefaultLayers = data?.selectedDefaultLayers ?? [];
    this.enableSnapping = data?.enableSnapping ?? false;
  }

  getState = () => {
    return {
      id: this.id,
      type: this.type,
      title: this.title,
      description: this.description,
      isRequired: this.isRequired,
      allCommunityLayers: this.allCommunityLayers,
      allGlobalCommunityLayers: this.allGlobalCommunityLayers,
      mapCenterPoint: this.mapCenterPoint,
      zoomLevel: this.zoomLevel,
      ableToCheckMultiple: this.ableToCheckMultiple,
      selectedDefaultLayers: this.selectedDefaultLayers,
      enableSnapping: this.enableSnapping,
    };
  };
}
class DropPinState {
  [x: string]: any;

  constructor(data?: any) {
    this.id = data?._id
      ? data?._id
      : data?.id ?? generateUniqueId("drop-a-pin#");
    this.type = questionTypes.dropPin;
    this.title = data?.title;
    this.description = data?.description ?? "";
    this.isRequired = data?.isRequired ?? false;
    this.allCommunityLayers = data?.allCommunityLayers ?? [];
    this.allGlobalCommunityLayers = data?.allGlobalCommunityLayers ?? [];
    this.mapCenterPoint = data?.mapCenterPoint ?? {
      longitude: "",
      latitude: "",
    };
    this.zoomLevel = data?.zoomLevel ?? 2;
    this.ableToCheckMultiple = data?.ableToCheckMultiple ?? false;
    this.selectedDefaultLayers = data?.selectedDefaultLayers ?? [];
    this.enableSnapping = data?.enableSnapping ?? false;
    this.markerStyle = data?.markerStyle ?? null;
    this.markerGroup = data?.markerGroup ?? null;
  }

  getState = () => {
    return {
      id: this.id,
      type: this.type,
      title: this.title,
      description: this.description,
      isRequired: this.isRequired,
      allCommunityLayers: this.allCommunityLayers,
      allGlobalCommunityLayers: this.allGlobalCommunityLayers,
      mapCenterPoint: this.mapCenterPoint,
      zoomLevel: this.zoomLevel,
      ableToCheckMultiple: this.ableToCheckMultiple,
      selectedDefaultLayers: this.selectedDefaultLayers,
      enableSnapping: this.enableSnapping,
      markerGroup: this.markerGroup,
      markerStyle: this.markerStyle,
    };
  };
}

class GetGpsDataState {
  [x: string]: any;

  constructor(data?: any) {
    this.id = data?._id
      ? data?._id
      : data?.id ?? generateUniqueId("get-gps-data#");
    this.type = questionTypes.getGpsData;
    this.title = data?.title;
    this.description = data?.description ?? "";
    this.isRequired = data?.isRequired ?? false;
    this.mapCenterPoint = data?.mapCenterPoint ?? {
      longitude: "",
      latitude: "",
    };
    this.zoomLevel = data?.zoomLevel ?? 2;
  }

  getState = () => {
    return {
      id: this.id,
      type: this.type,
      title: this.title,
      description: this.description,
      isRequired: this.isRequired,
      mapCenterPoint: this.mapCenterPoint,
      zoomLevel: this.zoomLevel,
    };
  };
}

export {
  ShapePickerState,
  PointPickerState,
  DrawPolygonState,
  DropPinState,
  GetGpsDataState,
};
