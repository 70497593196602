import {
  ERROR_LOADING_REPORTS,
  GET_ALL_REPORTS,
  RESET_DATA,
  START_LOADING_REPORTS,
  STOP_LOADING_REPORTS,
} from "./action-types";
import initialState, { IReports } from "./initialState";
export const reportsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case START_LOADING_REPORTS:
      return {
        ...state,
        stateIs: "Pending",
      };

    case ERROR_LOADING_REPORTS:
      return {
        ...state,
        stateIs: "Error",
      };

    case STOP_LOADING_REPORTS:
      return {
        ...state,
        stateIs: "Idle",
      };
    case GET_ALL_REPORTS:
      const reports = action.payload?.reports?.map((item: IReports) => {
        const {
          id,
          reportName,
          formsIncluded,
          lastRun,
          nextRun,
          runsInQueue,
          dateUpdated,
          recurrenceSettings,
          totalViews,
          totalDownloads,
          dateAdded,
          createdBy,
          type
        } = item;
        const data = {
          id,
          reportName: {
            reportName,
            id,
          },
          formsIncluded,
          lastRun,
          nextRun,
          runsInQueue,
          dateUpdated,
          recurrenceSettings,
          totalViews,
          totalDownloads,
          dateAdded,
          createdBy,
          type
        };
        return data;
      });

      return {
        ...state,
        data: {
          reports,
          totalItems: action.payload.totalItems,
          totalPages: action.payload.totalPages,
          currentPage: action.payload.currentPage,
        },
        message: "",
        stateIs: "Idle",
      };
    case RESET_DATA: {
      return initialState;
    }
    default:
      return state;
  }
};
