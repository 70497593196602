import React, { useState } from "react";
import SearchIcon from "assets/images/Auth/Vector.png";
import { CalenderIcon, EyeIcon, EyeOffIcon } from "assets/icons/HeroIcons";
import "./InputStyle.scss";
import CustomDatePicker from "../DatePicker";
import moment from "moment";
import { DATE_FORMATS } from "constants/dateFormats";


interface TextInputProps {
  label?: string;
  type?: string;
  error?: any;
  name?: string;
  fieldAs?: "text" | "select" | "date" | "password" | "searchData";
  data?: any[];
  placeholder?: any;
  onChange?: any;
  onBlur?: any;
  value?: any;
  options?: any;
  className?: any;
  min?: any;
  max?: any;
  ref?: any;
  onFocus?: any;
  inputClassName?: any;
  disabled?: any;
  inputValue?: string;
  setFieldValue?: any;
  touched?: any;
  isForFilters?: boolean;
}

const TextInputField: React.FC<TextInputProps> = ({
  label = "",
  type = "text",
  error,
  name,
  fieldAs = "text",
  data,
  placeholder,
  onChange,
  setFieldValue,
  onBlur,
  value,
  options,
  className,
  min,
  max,
  ref,
  onFocus,
  disabled,
  touched,
  inputClassName,
  inputValue,
  isForFilters = false,
  ...restProps
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const isPasswordInput = type === "password";
  const isColumnInput = type === "Search";

  return (
    <div className="flex flex-col w-full rounded-lg">
      <div className="relative flex flex-col">
        {label && (
          <p className="w-full pb-1 text-sm font-medium text-left capitalize text-textMid dark:text-inputText">
            {label}
          </p>
        )}
        {fieldAs === "select" ? (
          <>
            <select
              ref={ref}
              name={name}
              className={`flex justify-start items-center focus:outline-none gap-1.5 px-3 py-2 rounded bg-white border ${
                error
                  ? "border-accent_1Dark"
                  : "border-lineDark dark:border-lineLight"
              }`}
              onChange={onChange} // Pass the 'onChange' function to the select element
              {...restProps}
            >
              {data?.map((item: any, index) => (
                <option
                  key={index}
                  className="h-40 gap-4 p-2 w-592 bg-primaryExtraLight"
                >
                  {item}
                </option>
              ))}
            </select>
            <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative py-0.5">
              {error && (
                <p className="flex-grow w-[592px] text-xs text-left text-accent_1Dark">
                  {error}
                </p>
              )}
            </div>
          </>
        ) : fieldAs === "searchData" ? (
          <>
            <input
              type={showPassword ? "text" : type}
              className={`h-full w-full focus:outline-none  ${className}`}
              name={name}
              value={value}
              // setFieldValue={setFieldValue}
              ref={ref}
              placeholder={placeholder}
              onChange={onChange} // Pass the 'onChange' function to the input element
              {...restProps}
            />
          </>
        ) : type === "date" ? (
          <>
            <div
              className={`flex justify-start items-center w-full focus:outline-none gap-1.5 ${className} text-secondaryMid dark:text-textMain rounded bg-white border ${
                error
                  ? "border-accent_1Dark"
                  : "border-lineDark dark:border-lineLight"
              }`}
            >
              <CustomDatePicker
                {...restProps}
                max={max}
                min={min}
                initialValue={value}
                className={className}
                touched={touched}
                disabled={disabled}
                // sx={{border:"none"}}
                handleChange={(value: any) => {
                  onChange(value);
                }}
                isForFilters={isForFilters}
              />
            </div>
            <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative py-0.5">
              {error && (
                <p className="flex-grow text-xs text-left text-accent_1Dark">
                  {error}
                </p>
              )}
            </div>
          </>
        ) : (
          <>
            <div
              className={`   flex justify-start items-center w-full focus:outline-none gap-1.5 px-3 py-2 ${className} text-secondaryMid dark:text-textMain rounded bg-white border ${
                error
                  ? "border-accent_1Dark"
                  : "border-lineDark dark:border-lineLight"
              }`}
            >
              {isColumnInput && (
                <button type="button" className="focus:outline-none">
                  <img src={SearchIcon} alt="" />
                </button>
              )}
              <input
                type={showPassword ? "text" : type}
                className={`h-full w-full focus:outline-none date-input  dark:bg-secondaryLight resize-none gap-1.5 px-3 dark:text-inputText ${
                  inputClassName || className
                }`}
                name={name}
                placeholder={placeholder}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                ref={ref}
                disabled={disabled}
                onFocus={onFocus}
                {...restProps}
                max={max}
                min={min}
              />
              {/* {type === "date" && (
                <CalenderIcon className="calender-icon z-[1] absolute right-6 w-5 h-5 pointer-events-none" />
              )} */}
              {isPasswordInput && (
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="focus:outline-none"
                >
                  {showPassword ? (
                    <EyeIcon className="w-6 h-6" />
                  ) : (
                    <EyeOffIcon className="w-6 h-6" />
                  )}
                </button>
              )}
            </div>

            {error && (
              <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative py-0.5">
                <p className="flex-grow w-[1/2] text-xs text-left text-accent_1Dark">
                  {error}
                </p>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default TextInputField;
