import {
  ERROR_LOADING_REPORTS_PROFILE,
  REPORTS_VERSIONS,
  GET_ALL_REPORTS_PROFILE,
  RESET_DATA,
  START_LOADING_REPORTS_PROFILE,
  STOP_LOADING_REPORTS_PROFILE,
} from "./action-types";
import initialState, { IProfileReports } from "./initialState";
export const reportsProfileReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case START_LOADING_REPORTS_PROFILE:
      return {
        ...state,
        stateIs: "Pending",
      };

    case ERROR_LOADING_REPORTS_PROFILE:
      return {
        ...state,
        stateIs: "Error",
      };

    case STOP_LOADING_REPORTS_PROFILE:
      return {
        ...state,
        stateIs: "Idle",
      };
    case GET_ALL_REPORTS_PROFILE:
      const versions = action.payload?.versions?.map(
        (item: IProfileReports) => {
          const {
            id,
            version,
            processedReports,
            timeRange,
            processingStatus,
            observationsProcessed,
            viewed,
            downloaded,
            dateAdded,
            reportId,
            runDateTime,
          } = item;
          const data = {
            id,
            version,
            processedReports,
            timeRange,
            processingStatus,
            observationsProcessed,
            viewed,
            downloaded,
            dateAdded,
            reportId,
            runDateTime,
          };
          return data;
        }
      );

      return {
        ...state,
        data: {
          versions,
          totalItems: action.payload.totalItems,
          totalPages: action.payload.totalPages,
          dateAdded: action.payload.dateAdded,
          status: action.payload.status,
          recurrenceSettings: action.payload.recurrenceSettings,
          reportName: action.payload.reportName,
          type: action.payload.type
        },
        message: "",
        stateIs: "Idle",
      };
    case RESET_DATA: {
      return initialState;
    }
    default:
      return state;
  }
};
