import { useEffect, useState } from "react";
import { Geography, SurveyComponent } from "./Components";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { RootState } from "store";
import { IfetchSurveyJsFormDatails } from "store/formDetailsSurveyJsFields/initialState";
import { TailSpin } from "react-loader-spinner";
import { I_InitialStateOfSurveyJS } from "store/surveyJS/initialState";
import { fetchSurveyJSFormDetails } from "store/formDetailsSurveyJsFields/reducer.actions";
import { fetchDataSources } from "./Components/SurveyJs/utils/handlingOfDropDownsInHardCodeSections";

export const EditFormDetails = () => {
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const { formDetails, message, stateIs } = useSelector<
    RootState,
    IfetchSurveyJsFormDatails
  >((state) => state.formSurveyJsDetails);
  const { displayView } = useSelector<RootState, I_InitialStateOfSurveyJS>(
    (state) => state.surveyJS
  );

  const [dataSourcesData, setDataSourcesData] = useState({
    observers: [],
    types: [],
    objects: [],
    categories: [],
    species: [],
  });

  async function fetchAllDataSources(programId: any) {
    try {
      const [species, objects, observers, categories, types] =
        await Promise.all([
          fetchDataSources("species", programId),
          fetchDataSources("objects", programId),
          fetchDataSources("observers", programId),
          fetchDataSources("categories", programId),
          fetchDataSources("types", programId),
        ]);

      setDataSourcesData(() => {
        return {
          observers,
          types,
          objects,
          categories,
          species,
        };
      });
      // setObservers(observers);
      // setSpecies(species);
      // setObjects(objects);
      // setCategories(categories);
      // setTypes(types);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const { formId } = useParams();

  useEffect(() => {
    if (formId) {
      if (displayView === "survey") {
        dispatch(fetchSurveyJSFormDetails({ id: formId }));
      }
    }
  }, [formId, displayView]);

  useEffect(() => {
    if (stateIs === "Idle" && formDetails.programId !== undefined) {
      fetchAllDataSources(formDetails.programId);
    }
  }, [formDetails]);

  if (stateIs === "Pending") {
    return (
      <div className="loader min-h-[100vh] justify-center items-center flex w-full">
        <TailSpin
          height="50"
          width="50"
          color="#005C89"
          ariaLabel="tail-spin-loading"
          radius="2"
          wrapperStyle={{}}
          wrapperClass="tailspin-loader"
          visible={true}
        />
      </div>
    );
  } else if (stateIs === "Idle") {
    return (
      <>
        <SurveyComponent
          dataSourcesData={dataSourcesData}
          formDetails={formDetails}
          doHide={displayView === "geography"}
        />
        {displayView === "geography" ? (
          <Geography dataSourcesData={dataSourcesData} />
        ) : null}
      </>
    );
  }
};
