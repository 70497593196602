function convertJsonOfUpComingDropPin(question: any) {
  const {
    centerPoint,
    dropMultiple,
    enableSnapping,
    selectedLayers,
    id,
    title,
    type,
    name,
    description,
    isRequired,
    zoomLevel,
    markerGroup,
    markerStyle,
  } = question;
  const mapCenterPoint = {
    longitude: centerPoint && centerPoint[0],
    latitude: centerPoint && centerPoint[1],
  };

  const structuredData = {
    _id: id,
    name,
    title,
    type,
    description,
    isRequired,
    mapCenterPoint,
    zoomLevel: zoomLevel,
    ableToCheckMultiple: dropMultiple,
    enableSnapping,
    selectedDefaultLayers: selectedLayers,
    markerGroup,
    markerStyle,
  };

  return structuredData;
}

export { convertJsonOfUpComingDropPin };
