import { useState } from "react";
import usePermissions from "./usePermissions";
import { useParams } from "react-router-dom";
// import initialState, { Stepper, Step } from './initialState';
export interface Step {
  title: string;
  step: string;
  isActive: boolean;
  isSubmitted: boolean;
  hasAccess: boolean;
  needsReview: boolean;
  isUpdated: boolean;
  isBasic: boolean;
}

export interface Stepper {
  steps: Step[];
  currentReportId: any;
  reportName: string;
}

export const reportStepNames = {
  reportName: "reportName",
  permissions: "permissions",
  reportScheduling: "reportScheduling",
  // exporting: "exporting",
  geospatialFiltering: "geospatialFiltering",
  filtering: "filtering",
  dataFieldSelectionAndOrdering: "dataFieldSelectionAndOrdering",
};

export const useReportsStepper = () => {
  const { reportsId } = useParams();

  const initialState: Stepper = useSteps();

  const [stepper, setStepper] = useState<Stepper>(initialState);
  const urlParams = new URLSearchParams(window.location.search);
  const reportType = urlParams.get("type");

  const stepsImpact = {
    reportName:
      reportType === "basic"
        ? ["permissions", "geospatialFiltering", "filtering"]
        : [
            "permissions",
            "geospatialFiltering",
            "dataFieldSelectionAndOrdering",
            "filtering",
          ],
    permissions: [],
    reportScheduling: ["filtering"],
    // exporting: [],
    // geospatialFiltering: ["filtering"],
    dataFieldSelectionAndOrdering: ["filtering"],
    filtering: [],
  };

  const stepSequence = [
    "reportName",
    "reportScheduling",
    "dataFieldSelectionAndOrdering",
    // "exporting",
    "filtering",
    "geospatialFiltering",
    "permissions",
  ];

  const isStepForward = (targetStep: any, currentStep: string) => {
    if (targetStep.needsReview && !targetStep.isSubmitted) {
      return true;
    }
    const currentIndex = stepSequence.indexOf(currentStep);
    const targetIndex = stepSequence.indexOf(targetStep.step);
    return targetIndex > currentIndex;
  };

  type CurrentStep = keyof typeof stepsImpact;

  function checkIfReviewNeeded(
    updatedStep: Step,
    currentStep: Step,
    affectedSteps: any[]
  ) {
    if (updatedStep.needsReview) {
      return isStepForward(updatedStep, currentStep.step);
    } else if (currentStep.isUpdated) {
      if (updatedStep.needsReview) {
        return isStepForward(updatedStep, currentStep.step);
      } else {
        return affectedSteps.includes(updatedStep.step);
      }
    } else {
      return false;
    }
  }

  const moveToNextStep = (currentStep: CurrentStep, skipStep = false) => {
    const urlParams = new URLSearchParams(window.location.search);
    const reportType = urlParams.get("type");

    setStepper((prev) => {
      const currentIndex = prev.steps.findIndex(
        (step) => step.step === currentStep
      );
      const currentFullStep: any = prev.steps.find(
        (step) => step.step === currentStep
      );
      const nextIndex =
        currentIndex < prev.steps.length - 1 && skipStep
          ? currentIndex + 2
          : currentIndex < prev.steps.length - 1
            ? currentIndex + 1
            : currentIndex;
      const affectedSteps: any = stepsImpact[currentStep] || [];
      return {
        ...prev,
        steps: prev.steps
          .filter((step) => {
            // If type is 'basic', include only steps with basic: true or no key
            if (reportType === "basic") {
              return step.isBasic === true;
            }
            return true; // Include all steps for other types
          })
          .map((step, index) => {
            const updatedStep = {
              ...step,
              isSubmitted: step.isSubmitted
                ? step.isSubmitted
                : step.step === currentStep,
            };

            const needsReview = checkIfReviewNeeded(
              updatedStep,
              currentFullStep,
              affectedSteps
            );

            return {
              ...updatedStep,
              isActive: index === nextIndex,
              needsReview: reportsId ? needsReview : false,
            };
          }),
      };
    });
  };

  const moveToPreviousStep = (skipStep?: boolean) => {
    setStepper((prev) => {
      const currentIndex = prev.steps.findIndex((step) => step.isActive);
      const prevIndex =
        currentIndex > 0 && skipStep
          ? currentIndex - 2
          : currentIndex > 0
            ? currentIndex - 1
            : 0;

      return {
        ...prev,
        steps: prev.steps.map((step, index) => ({
          ...step,
          isActive: index === prevIndex,
        })),
      };
    });
  };

  const moveToSpecificStep = (stepName: string) => {
    setStepper((prev) => ({
      ...prev,
      steps: prev.steps.map((step) => ({
        ...step,
        isActive: step.step === stepName,
      })),
    }));
  };

  const setReportId = (reportId: string | number) => {
    setStepper((prev) => ({
      ...prev,
      currentReportId: reportId,
    }));
  };

  const setReportName = (name: string) => {
    setStepper((prev) => ({
      ...prev,
      reportName: name,
    }));
  };

  function setAnythingUpdatedThisStep(currentStep: string, isUpdated: boolean) {
    setStepper((prev) => {
      // const currentIndex = prev.steps.findIndex(
      //   (step) => step.step === currentStep
      // );
      // const nextIndex =
      //   currentIndex < prev.steps.length - 1 ? currentIndex + 1 : currentIndex;
      // const affectedSteps: any = stepsImpact[currentStep] || [];
      return {
        ...prev,
        steps: prev.steps.map((step, index) => {
          // const updatedStep = {
          //   ...step,
          //   isSubmitted: step.isSubmitted
          //     ? step.isSubmitted
          //     : step.step === currentStep,
          // };
          // const needsReview = updatedStep.needsReview
          //   ? isStepForward(updatedStep, currentStep)
          //   : affectedSteps.includes(updatedStep.step);

          return {
            ...step,
            isUpdated: step.step === currentStep ? isUpdated : step.isUpdated,
          };
        }),
      };
    });
  }
  const resetStepper = () => {
    setStepper(initialState);
  };

  return {
    stepper,
    moveToNextStep,
    moveToPreviousStep,
    moveToSpecificStep,
    setReportId,
    setReportName,
    resetStepper,
    setAnythingUpdatedThisStep,
  };
};

export default useReportsStepper;

function useSteps() {
  const { reports } = usePermissions();
  const [stepper, setStepper] = useState(() => {
    const steps = [
      {
        title: "Report Name",
        step: reportStepNames.reportName,
        isActive: true,
        isSubmitted: false,
        hasAccess: reports.canCreateReports,
        needsReview: false,
        isUpdated: false,
        isBasic: true,
      },
      {
        title: "Report Scheduling",
        step: reportStepNames.reportScheduling,
        isActive: false,
        isSubmitted: false,
        hasAccess: true,
        // hasAccess: reports.canCreateSettingReports,
        needsReview: false,
        isUpdated: false,
        isBasic: false,
      },
      {
        title: "Data Field Selection and Ordering",
        step: reportStepNames.dataFieldSelectionAndOrdering,
        isActive: false,
        isSubmitted: false,
        hasAccess: reports.canViewColumnsReports,
        needsReview: false,
        isUpdated: false,
        isBasic: true,
      },
      {
        title: "Filtering",
        step: reportStepNames.filtering,
        isActive: false,
        isSubmitted: false,
        hasAccess: true,
        // hasAccess: reports.canCreateFilteringReports,
        needsReview: false,
        isUpdated: false,
        isBasic: false,
      },
      {
        title: "Geographic Filtering",
        step: reportStepNames.geospatialFiltering,
        isActive: false,
        isSubmitted: false,
        hasAccess: reports.canViewGeoSpatialColumnsReports,
        needsReview: false,
        isUpdated: false,
        isBasic: false,
      },
      {
        title: "Permissions",
        step: reportStepNames.permissions,
        isActive: false,
        isSubmitted: false,
        hasAccess: reports.canViewAdminsReports,
        needsReview: false,
        isUpdated: false,
        isBasic: true,
      },
      // {
      //   title: "Exporting",
      //   step: reportStepNames.exporting,
      //   isActive: false,
      //   isSubmitted: false,
      //   hasAccess: true,
      //   // hasAccess: reports.canEditExportPermissionReports,
      //   needsReview: false,
      //   isUpdated: false,
      // },
    ];

    const accessedSteps = steps.filter((step) => step.hasAccess);

    return {
      steps: accessedSteps,
      currentReportId: null,
      reportName: "",
    };
  });

  return stepper;
}
