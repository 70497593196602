// import { useEffect } from "react";
// import { useSelector } from "react-redux";
// import { RootState } from "store";
// import structureFormJSON from "../utils/structureFormJSON";
// import { panelsJson } from "..";
// import questionTypes from "../questionTypes";
// import {
//   DrawPolygonState,
//   DropPinState,
//   PointPickerState,
//   ShapePickerState,
// } from "../stateConstructors";
// import {
//   updateAddedGeographicalPanelMapQuestions,
//   updateAddedHarvestPanelMapQuestions,
//   updateDrawPolygons,
//   updateDropPins,
//   updatePointPickers,
//   updateShapePickers,
// } from "store/surveyJS";
// import { useDispatch } from "react-redux";
// import appendElementToArray from "../utils/appendElementToArray";
// import { ignoreUndoRedo } from "survey-creator-core";
// import { panelsName } from "../panelsTitle";
// import generateUniqueId from "../utils/generateUniqueId";

// const useHandleEditModeMapTypeComponents = () => {
//   const formDetails = useSelector((state: RootState) => state.formSurveyJsDetails);
//   const {
//     shapePickers,
//     pointPickers,
//     dropPins,
//     drawPolygons,
//     allCommunityLayers,
//     allCommunityPoints,
//     allGlobalCommunityLayers,
//   } = useSelector((state: RootState) => state.surveyJS);
//   const dispatch = useDispatch();

//   // creating state instances of map type questions on edit mode and store them to global state
//   useEffect(() => {
//     if (
//       formDetails.formFields?.pages &&
//       allCommunityLayers &&
//       allCommunityLayers.length > 0 &&
//       allCommunityPoints &&
//       allCommunityPoints.length > 0
//     ) {
//       const newJson = structureFormJSON(
//         formDetails?.formFields,
//         panelsJson,
//         [],
//         "response"
//       );

//       let updatedShapePickersState = [...(shapePickers ?? [])],
//         updatedPointPickersState = [...(pointPickers ?? [])],
//         updatedDropPinsState = [...(dropPins ?? [])],
//         updatedDrawPolygonsState = [...(drawPolygons ?? [])];
//       let harvest;
//       let geographicalPanel;

//       newJson.pages[0].elements.forEach((element: any) => {
//         if (element.templateElements) {
//           element.templateElements.forEach((question: any) => {
//             const questionInstance: any = initializeQuestionState(question);

//             if (questionInstance.type === questionTypes.pointPicker) {
//               questionInstance.allCommunityMarkers = allCommunityPoints?.map(
//                 (point: any) => ({
//                   ...point,
//                   id: questionInstance.id + "-" + point.id,
//                 })
//               );
//               questionInstance.allGlobalCommunityLayers =
//                 allGlobalCommunityLayers;
//             } else if (questionInstance.type === questionTypes.shapePicker) {
//               questionInstance.allCommunityLayers = allCommunityLayers?.map(
//                 (layer: any) => ({
//                   ...layer,
//                   id: questionInstance.id + "-" + layer.id,
//                 })
//               );
//               questionInstance.allGlobalCommunityLayers =
//                 allGlobalCommunityLayers;
//             } else if (
//               questionInstance.type === questionTypes.drawPolygon ||
//               questionInstance.type === questionTypes.dropPin
//             ) {
//               questionInstance.allGlobalCommunityLayers =
//                 allGlobalCommunityLayers?.filter(
//                   (l: any) => l?.type === "vector"
//                 );
//             }

//             switch (questionInstance?.type) {
//               case questionTypes.shapePicker:
//                 appendElementToArray(
//                   questionInstance,
//                   updatedShapePickersState
//                 );
//                 break;

//               case questionTypes.pointPicker:
//                 appendElementToArray(
//                   questionInstance,
//                   updatedPointPickersState
//                 );
//                 break;

//               case questionTypes.drawPolygon:
//                 appendElementToArray(
//                   questionInstance,
//                   updatedDrawPolygonsState
//                 );
//                 break;

//               case questionTypes.dropPin:
//                 appendElementToArray(questionInstance, updatedDropPinsState);
//             }
//           });
//         } else if (element.elements) {
//           element.elements.forEach((question: any) => {
//             const questionInstance: any = initializeQuestionState(question);

//             if (questionInstance.type === questionTypes.pointPicker) {
//               questionInstance.allCommunityMarkers = allCommunityPoints?.map(
//                 (point: any) => ({
//                   ...point,
//                   id: questionInstance.id + "-" + point.id,
//                 })
//               );
//               questionInstance.allGlobalCommunityLayers =
//                 allGlobalCommunityLayers;
//             } else if (questionInstance.type === questionTypes.shapePicker) {
//               questionInstance.allCommunityLayers = allCommunityLayers?.map(
//                 (layer: any) => ({
//                   ...layer,
//                   id: questionInstance.id + "-" + layer.id,
//                 })
//               );
//               questionInstance.allGlobalCommunityLayers =
//                 allGlobalCommunityLayers;
//             } else if (
//               questionInstance.type === questionTypes.drawPolygon ||
//               questionInstance.type === questionTypes.dropPin
//             ) {
//               questionInstance.allGlobalCommunityLayers =
//                 allGlobalCommunityLayers?.filter(
//                   (l: any) => l?.type === "vector"
//                 );
//             }

//             switch (questionInstance?.type) {
//               case questionTypes.shapePicker:
//                 appendElementToArray(
//                   questionInstance,
//                   updatedShapePickersState
//                 );
//                 break;

//               case questionTypes.pointPicker:
//                 appendElementToArray(
//                   questionInstance,
//                   updatedPointPickersState
//                 );
//                 break;

//               case questionTypes.drawPolygon:
//                 appendElementToArray(
//                   questionInstance,
//                   updatedDrawPolygonsState
//                 );
//                 break;

//               case questionTypes.dropPin:
//                 appendElementToArray(questionInstance, updatedDropPinsState);
//             }
//           });
//         } else if (element.name === panelsName.HARVEST) {
//           const questionInstance: any = initializeQuestionState(element);

//           questionInstance.shapePicker.allCommunityLayers =
//             allCommunityLayers?.map((layer: any) => ({
//               ...layer,
//               id: questionInstance.shapePicker.id + "-" + layer.id,
//             }));
//           questionInstance.shapePicker.allGlobalCommunityLayers =
//             allGlobalCommunityLayers;

//           questionInstance.pointPicker.allCommunityMarkers =
//             allCommunityPoints?.map((point: any) => ({
//               ...point,
//               id: questionInstance.pointPicker.id + "-" + point.id,
//             }));
//           questionInstance.pointPicker.allGlobalCommunityLayers =
//             allGlobalCommunityLayers;

//           harvest = questionInstance;
//         } else if (element.name === panelsName.GEOGRAPHICAL_INFORMATION) {
//           const questionInstance: any = initializeQuestionState(element);

//           questionInstance.regionTypeShapePicker.allCommunityLayers =
//             allCommunityLayers?.map((layer: any) => ({
//               ...layer,
//               id: questionInstance.regionTypeShapePicker.id + "-" + layer.id,
//             }));
//           questionInstance.regionTypeShapePicker.allGlobalCommunityLayers =
//             allGlobalCommunityLayers;

//           questionInstance.viewTypeShapePicker.allCommunityLayers =
//             allCommunityLayers?.map((layer: any) => ({
//               ...layer,
//               id: questionInstance.viewTypeShapePicker.id + "-" + layer.id,
//             }));
//           questionInstance.viewTypeShapePicker.allGlobalCommunityLayers =
//             allGlobalCommunityLayers;

//           questionInstance.pointPicker.allCommunityMarkers =
//             allCommunityPoints?.map((point: any) => ({
//               ...point,
//               id: questionInstance.pointPicker.id + "-" + point.id,
//             }));
//           questionInstance.pointPicker.allGlobalCommunityLayers =
//             allGlobalCommunityLayers;

//           geographicalPanel = questionInstance;
//         }
//       });

//       dispatch(updateShapePickers(updatedShapePickersState));
//       dispatch(updatePointPickers(updatedPointPickersState));
//       dispatch(updateDrawPolygons(updatedDrawPolygonsState));
//       dispatch(updateDropPins(updatedDropPinsState));
//       dispatch(updateAddedHarvestPanelMapQuestions(harvest));
//       dispatch(updateAddedGeographicalPanelMapQuestions(geographicalPanel));
//     }
//   }, [
//     formDetails,
//     allCommunityLayers,
//     allCommunityPoints,
//     allGlobalCommunityLayers,
//   ]);

//   return null;
// };

// export { useHandleEditModeMapTypeComponents };

// function initializeQuestionState(question: any) {
//   let questionInstance;

//   switch (question.type) {
//     case questionTypes.shapePicker:
//       questionInstance = new ShapePickerState(question);
//       break;

//     case questionTypes.pointPicker:
//       questionInstance = new PointPickerState(question);
//       break;

//     case questionTypes.drawPolygon:
//       questionInstance = new DrawPolygonState(question);
//       break;

//     case questionTypes.dropPin:
//       questionInstance = new DropPinState(question);
//       break;

//     case panelsName.GEOGRAPHICAL_INFORMATION:
//       // handle map type components in panels

//       const regionTypeShapePicker = new ShapePickerState(
//         question.regionTypeShapePicker
//       );
//       const viewTypeShapePicker = new ShapePickerState(
//         question.viewTypeShapePicker
//       );
//       const geographicalPointPicker = new PointPickerState(
//         question.pointPicker
//       );

//       const geographical = {
//         id: question._id,
//         type: panelsName.GEOGRAPHICAL_INFORMATION,
//         regionTypeShapePicker,
//         viewTypeShapePicker,
//         pointPicker: geographicalPointPicker,
//       };

//       questionInstance = geographical;
//       break;

//     case panelsName.HARVEST:
//       const shapePicker = new ShapePickerState(question.shapePicker);
//       const pointPicker = new PointPickerState(question.pointPicker);

//       const harvest = {
//         id: question._id,
//         type: panelsName.HARVEST,
//         shapePicker,
//         pointPicker,
//       };

//       questionInstance = harvest;
//       break;

//     default:
//       questionInstance = question;
//   }
//   if (questionInstance) {
//     question._id = questionInstance.id;
//     question.rdx_id = questionInstance.id;
//   }
//   return questionInstance;
// }

import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import structureFormJSON from "../utils/structureFormJSON";
import { panelsJson } from "..";
import questionTypes from "../questionTypes";
import {
  DrawPolygonState,
  DropPinState,
  GetGpsDataState,
  PointPickerState,
  ShapePickerState,
} from "../stateConstructors";
import {
  updateAddedGeographicalPanelMapQuestions,
  updateAddedHarvestPanelMapQuestions,
  updateDrawPolygons,
  updateDropPins,
  updateGetGpsDatas,
  updatePointPickers,
  updateShapePickers,
} from "store/surveyJS";
import { useDispatch } from "react-redux";
import appendElementToArray from "../utils/appendElementToArray";
import { ignoreUndoRedo } from "survey-creator-core";
import { panelsName } from "../panelsTitle";
import generateUniqueId from "../utils/generateUniqueId";
import { IfetchFormDatails } from "store/formDetails/initialState";
import {
  handleHarverstAndGeographicalInformationPanels,
  pickerKeyMap,
  updateSinglePicker,
} from "../components/RefreshDynamicFormGeographyBtn";

const useHandleEditModeMapTypeComponents = () => {
  const { formDetails, message, stateIs } = useSelector<
    RootState,
    IfetchFormDatails
  >((state) => state.formSurveyJsDetails);
  const {
    shapePickers,
    pointPickers,
    dropPins,
    drawPolygons,
    getGpsDatas,
    allCommunityLayers,
    allCommunityPoints,
    allGlobalCommunityLayers,
    isAllCommunityLayersFetched,
    isAllCommunityPointsFetched,
    isAllGlobalCommunityLayersFetched,
  } = useSelector((state: RootState) => state.surveyJS);
  const dispatch = useDispatch();

  // creating state instances of map type questions on edit mode and store them to global state
  useEffect(() => {
    if (
      formDetails.formFields?.pages &&
      isAllCommunityLayersFetched &&
      isAllCommunityPointsFetched &&
      isAllGlobalCommunityLayersFetched
      // ((allCommunityLayers && allCommunityLayers.length > 0) ||
      //   (allCommunityPoints && allCommunityPoints.length > 0) ||
      //   (allGlobalCommunityLayers && allGlobalCommunityLayers.length > 0))
    ) {
      const newJson = structureFormJSON(
        formDetails?.formFields,
        panelsJson,
        [],
        "response"
      );
      let updatedShapePickersState = [...(shapePickers ?? [])],
        updatedPointPickersState = [...(pointPickers ?? [])],
        updatedDropPinsState = [...(dropPins ?? [])],
        updatedDrawPolygonsState = [...(drawPolygons ?? [])],
        updatedGetGpsDatasState = [...(getGpsDatas ?? [])];
      let harvest;
      let geographicalPanel;

      newJson.pages[0].elements.forEach((element: any) => {
        // debugger;
        if (element.name === panelsName.HARVEST) {
          const questionInstance: any = initializeQuestionState(element);
          // questionInstance.retrievalShapePicker.allCommunityLayers =
          //   allCommunityLayers?.map((layer: any) => ({
          //     ...layer,
          //     id: questionInstance.retrievalShapePicker.id + "-" + layer.id,
          //   }));
          // questionInstance.struckShapePicker.allCommunityLayers =
          //   allCommunityLayers?.map((layer: any) => ({
          //     ...layer,
          //     id: questionInstance.struckShapePicker.id + "-" + layer.id,
          //   }));
          // questionInstance.retrievalShapePicker.allGlobalCommunityLayers =
          //   allGlobalCommunityLayers;
          // questionInstance.struckShapePicker.allGlobalCommunityLayers =
          //   allGlobalCommunityLayers;

          // questionInstance.retrievalPointPicker.allCommunityMarkers =
          //   allCommunityPoints?.map((point: any) => ({
          //     ...point,
          //     id: questionInstance.retrievalPointPicker.id + "-" + point.id,
          //   }));
          // questionInstance.struckPointPicker.allCommunityMarkers =
          //   allCommunityPoints?.map((point: any) => ({
          //     ...point,
          //     id: questionInstance.struckPointPicker.id + "-" + point.id,
          //   }));
          // questionInstance.retrievalPointPicker.allGlobalCommunityLayers =
          //   allGlobalCommunityLayers;
          // questionInstance.struckPointPicker.allGlobalCommunityLayers =
          //   allGlobalCommunityLayers;
          // const updatedHarvest = handleHarverstAndGeographicalInformationPanels(
          //   questionInstance,
          //   allCommunityLayers,
          //   allCommunityPoints,
          //   allGlobalCommunityLayers
          // );

          // console.log("updatedHarvest", updatedHarvest);
          harvest = handleHarverstAndGeographicalInformationPanels(
            questionInstance,
            allCommunityLayers,
            allCommunityPoints,
            allGlobalCommunityLayers
          );
        } else if (element.name === panelsName.GEOGRAPHICAL_INFORMATION) {
          const questionInstance: any = initializeQuestionState(element);
          // const updatedFuntion = updateSinglePicker(
          //   questionInstance.regionTypeShapePicker,
          //   allCommunityLayers,
          //   allCommunityPoints,
          //   allGlobalCommunityLayers
          // );
          // console.log("updated", updatedFuntion);
          // questionInstance.regionTypeShapePicker.allCommunityLayers =
          //   allCommunityLayers?.map((layer: any) => ({
          //     ...layer,
          //     id: questionInstance.regionTypeShapePicker.id + "-" + layer.id,
          //   }));
          // questionInstance.regionTypeShapePicker.allGlobalCommunityLayers =
          //   allGlobalCommunityLayers;

          // questionInstance.viewTypeShapePicker.allCommunityLayers =
          //   allCommunityLayers?.map((layer: any) => ({
          //     ...layer,
          //     id: questionInstance.viewTypeShapePicker.id + "-" + layer.id,
          //   }));
          // questionInstance.viewTypeShapePicker.allGlobalCommunityLayers =
          //   allGlobalCommunityLayers;

          // questionInstance.pointPicker.allCommunityMarkers =
          //   allCommunityPoints?.map((point: any) => ({
          //     ...point,
          //     id: questionInstance.pointPicker.id + "-" + point.id,
          //   }));
          // questionInstance.pointPicker.allGlobalCommunityLayers =
          //   allGlobalCommunityLayers;

          geographicalPanel = handleHarverstAndGeographicalInformationPanels(
            questionInstance,
            allCommunityLayers,
            allCommunityPoints,
            allGlobalCommunityLayers
          );
        } else if (element.templateElements) {
          element.templateElements.forEach((question: any) => {
            const updatedQuestion = updateSinglePicker(
              question,
              allCommunityLayers,
              allCommunityPoints,
              allGlobalCommunityLayers
            );

            const questionInstance: any =
              initializeQuestionState(updatedQuestion);

            // if (questionInstance.type === questionTypes.pointPicker) {
            //   questionInstance.allCommunityMarkers = allCommunityPoints?.map(
            //     (point: any) => ({
            //       ...point,
            //       id: questionInstance.id + "-" + point.id,
            //     })
            //   );

            //   // questionInstance.selectedPointsIdToHighlight= seletedie
            //   questionInstance.allGlobalCommunityLayers =
            //     allGlobalCommunityLayers;
            // } else if (questionInstance.type === questionTypes.shapePicker) {
            //   questionInstance.allCommunityLayers = allCommunityLayers?.map(
            //     (layer: any) => ({
            //       ...layer,
            //       id: questionInstance.id + "-" + layer.id,
            //     })
            //   );
            //   questionInstance.allGlobalCommunityLayers =
            //     allGlobalCommunityLayers;
            // } else if (
            //   questionInstance.type === questionTypes.drawPolygon ||
            //   questionInstance.type === questionTypes.dropPin
            // ) {
            //   questionInstance.allGlobalCommunityLayers =
            //     allGlobalCommunityLayers?.filter(
            //       (l: any) => l?.type === "vector"
            //     );
            // }
            // console.log("updatedShapePickersState", updatedShapePickersState);
            switch (questionInstance?.type) {
              case questionTypes.shapePicker:
                appendElementToArray(
                  questionInstance,
                  updatedShapePickersState
                );
                break;

              case questionTypes.pointPicker:
                appendElementToArray(
                  questionInstance,
                  updatedPointPickersState
                );
                break;

              case questionTypes.drawPolygon:
                appendElementToArray(
                  questionInstance,
                  updatedDrawPolygonsState
                );
                break;

              case questionTypes.dropPin:
                appendElementToArray(questionInstance, updatedDropPinsState);
                break;
              case questionTypes.getGpsData:
                appendElementToArray(questionInstance, updatedGetGpsDatasState);
            }
          });
        } else if (element.elements) {
          element.elements.forEach((question: any) => {
            const updatedQuestion = updateSinglePicker(
              question,
              allCommunityLayers,
              allCommunityPoints,
              allGlobalCommunityLayers
            );

            const questionInstance: any =
              initializeQuestionState(updatedQuestion);

            // if (questionInstance.type === questionTypes.pointPicker) {
            //   questionInstance.allCommunityMarkers = allCommunityPoints?.map(
            //     (point: any) => ({
            //       ...point,
            //       id: questionInstance.id + "-" + point.id,
            //     })
            //   );
            //   questionInstance.allGlobalCommunityLayers =
            //     allGlobalCommunityLayers;
            // } else if (questionInstance.type === questionTypes.shapePicker) {
            //   questionInstance.allCommunityLayers = allCommunityLayers?.map(
            //     (layer: any) => ({
            //       ...layer,
            //       id: questionInstance.id + "-" + layer.id,
            //     })
            //   );
            //   questionInstance.allGlobalCommunityLayers =
            //     allGlobalCommunityLayers;
            // } else if (
            //   questionInstance.type === questionTypes.drawPolygon ||
            //   questionInstance.type === questionTypes.dropPin
            // ) {
            //   questionInstance.allGlobalCommunityLayers =
            //     allGlobalCommunityLayers?.filter(
            //       (l: any) => l?.type === "vector"
            //     );
            // }

            switch (questionInstance?.type) {
              case questionTypes.shapePicker:
                appendElementToArray(
                  questionInstance,
                  updatedShapePickersState
                );
                break;

              case questionTypes.pointPicker:
                appendElementToArray(
                  questionInstance,
                  updatedPointPickersState
                );
                break;

              case questionTypes.drawPolygon:
                appendElementToArray(
                  questionInstance,
                  updatedDrawPolygonsState
                );
                break;

              case questionTypes.dropPin:
                appendElementToArray(questionInstance, updatedDropPinsState);
                break;
              case questionTypes.getGpsData:
                appendElementToArray(questionInstance, updatedGetGpsDatasState);
            }
          });
        }
      });
      dispatch(updateShapePickers(updatedShapePickersState));
      dispatch(updateGetGpsDatas(updatedGetGpsDatasState));
      dispatch(updatePointPickers(updatedPointPickersState));
      dispatch(updateDrawPolygons(updatedDrawPolygonsState));
      dispatch(updateDropPins(updatedDropPinsState));
      dispatch(updateAddedHarvestPanelMapQuestions(harvest));
      dispatch(updateAddedGeographicalPanelMapQuestions(geographicalPanel));
    }
  }, [
    formDetails,
    allCommunityLayers,
    allCommunityPoints,
    allGlobalCommunityLayers,
  ]);

  return null;
};

export { useHandleEditModeMapTypeComponents };

function initializeQuestionState(question: any) {
  let questionInstance;
  const questionType =
    question.panelType === "static" ? question.name : question.type;
  switch (questionType) {
    case questionTypes.shapePicker:
      console.log("question", question);
      const questionInitialized = new ShapePickerState(question);
      console.log("questionInitialized", questionInitialized);
      questionInstance = questionInitialized;
      break;

    case questionTypes.pointPicker:
      questionInstance = new PointPickerState(question);
      break;

    case questionTypes.drawPolygon:
      questionInstance = new DrawPolygonState(question);
      break;

    case questionTypes.dropPin:
      questionInstance = new DropPinState(question);
      break;
    case questionTypes.getGpsData:
      questionInstance = new GetGpsDataState(question);
      break;

    case panelsName.GEOGRAPHICAL_INFORMATION:
      // handle map type components in panels

      const regionTypeShapePicker = new ShapePickerState(
        question.regionTypeShapePicker
      );
      const viewTypeShapePicker = new ShapePickerState(
        question.viewTypeShapePicker
      );
      const geographicalPointPicker = new PointPickerState(
        question.pointPicker
      );

      const geographical = {
        id: question._id,
        type: panelsName.GEOGRAPHICAL_INFORMATION,
        regionTypeShapePicker,
        viewTypeShapePicker,
        pointPicker: geographicalPointPicker,
      };

      questionInstance = geographical;
      break;

    case panelsName.HARVEST:
      const retrievalShapePicker = new ShapePickerState(
        question.retrievalShapePicker
      );

      const retrievalPointPicker = new PointPickerState(
        question.retrievalPointPicker
      );
      const struckShapePicker = new ShapePickerState(
        question.struckShapePicker
      );
      const struckPointPicker = new PointPickerState(
        question.struckPointPicker
      );

      const harvest = {
        id: question._id,
        type: panelsName.HARVEST,
        retrievalShapePicker,
        retrievalPointPicker,
        struckShapePicker,
        struckPointPicker,
      };

      questionInstance = harvest;
      break;

    default:
      questionInstance = question;
  }
  if (questionInstance) {
    question._id = questionInstance.id;
    question.rdx_id = questionInstance.id;
  }

  return questionInstance;
}
