export interface IForms {
  id: number;
  name: string;
}

export interface IReports {
  createdBy: {
    image: {
      original: string;
      thumb: string;
    };
    id: number;
    name: string;
  };
  reportName: string;
  formsIncluded: IForms[];
  lastRun: string;
  nextRun: string;
  recurrenceSettings: string;
  runsInQueue: number;
  id: number;
  totalViews: number;
  totalDownloads: number;
  dateAdded: string;
  dateUpdated: string;
  type: string;
}
export interface IreportsData {
  reports: IReports[];
  totalItems: number;
  totalPages: number;
}
export interface IfetchReports {
  data: IreportsData;
  message: string;
  stateIs: "Idle" | "Pending" | "Error";
}
const initialReportsData: IfetchReports = {
  data: {
    reports: [],
    totalItems: 0,
    totalPages: 0,
  },
  message: "",
  stateIs: "Idle",
};
export default initialReportsData;
