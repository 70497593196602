export interface Step {
  title: string;
  step: string;
  isActive: boolean;
  isSubmitted: boolean;
}

export interface Stepper {
  steps: Step[];
  currentReportId: any;
  reportName: string;
  regions: any;
}

export const reportStepNames = {
  reportName: "reportName",
  permissions: "permissions",
  reportScheduling: "reportScheduling",
  // exporting: "exporting",
  geospatialFiltering: "geospatialFiltering",
  filtering: "filtering",
  dataFieldSelectionAndOrdering: "dataFieldSelectionAndOrdering",
};

const initialState: Stepper = {
  steps: [
    {
      title: "Report Name",
      step: reportStepNames.reportName,
      isActive: true,
      isSubmitted: false,
      // isVisible: false,
    },
    {
      title: "Permissions",
      step: reportStepNames.permissions,
      isActive: false,
      isSubmitted: false,
    },
    {
      title: "Report Scheduling",
      step: reportStepNames.reportScheduling,
      isActive: false,
      isSubmitted: false,
    },
    // {
    //   title: "Exporting",
    //   step: reportStepNames.exporting,
    //   isActive: false,
    //   isSubmitted: false,
    // },

    {
      title: "Geographic Filtering",
      step: reportStepNames.geospatialFiltering,
      isActive: false,
      isSubmitted: false,
    },
    {
      title: "Data Field Selection and Ordering",
      step: reportStepNames.dataFieldSelectionAndOrdering,
      isActive: false,
      isSubmitted: false,
    },
    {
      title: "Filtering",
      step: reportStepNames.filtering,
      isActive: false,
      isSubmitted: false,
    },
  ],
  currentReportId: null,
  reportName: "",
  regions: [
    {
      name: "",
      value: "",
      type: "",
    },
  ],
};

export default initialState;
