function appendElementToArray(
  element: { id: string; [key: string]: any },
  list: { id: string; [key: string]: any }[]
) {
  const index = list.findIndex((ele) => ele.id === element.id);

  if (index !== -1) {
    // Replace the existing element with the new element if found
    list[index] = element;
  } else {
    // Push the new element if not found
    list.push(element);
  }

  return list;
}
export default appendElementToArray;
