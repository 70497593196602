export interface IProfileReports {
  responseData: any;
  title: string;
  type: string;
  downloadPermissions: any;
}
export interface IfetchResultReports {
  data: IProfileReports;
  message: string;
  stateIs: "Idle" | "Pending" | "Error";
}
const initialResultReportsData: any = {
  data: {
    responseData: '',
    title: '',
    type: "",
    downloadPermissions: false,
  },
  message: "",
  stateIs: "Idle",
};
export default initialResultReportsData;