import { apiClient } from "../config";

async function getProgramTimeRange(programId: number) {
  const res = await apiClient.get(
    `/admin/programs/${programId}/time-range`
  );
  return res.data;
}

export default getProgramTimeRange;
