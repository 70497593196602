import { SurveyQuestionElementBase } from "survey-react-ui";

import Map from "./Map";
import { TailSpin } from "react-loader-spinner";
import store from "store";

export const getGpsDataProperties = [
  {
    name: "_id",
    category: "general",
    visibleIndex: 2,
  },
];
export default class SurveyGetGpsDataQuestion extends SurveyQuestionElementBase {
  constructor(props: any) {
    super(props);

    this.state = { value: this.question.value };
  }

  get question() {
    return this.questionBase;
  }

  get value() {
    return this.question.value;
  }

  renderInput() {
    return <div>ITS a get gps data componenet and render will be soon!</div>;
  }

  renderElement() {
    const isRefreshingGeographies =
      store.getState()?.surveyJS?.isRefreshingGeographies ?? false;
    return (
      <div>
        <div>
          <div className="grid items-end gap-4 mb-6 md:grid-cols-3">
            <div>
              <label
                htmlFor="Latitude"
                style={{
                  fontStyle: "normal",
                  fontWeight: "600px",
                  fontSize: "16px",
                  lineHeight: "24px",
                  fontFamily: "Open sans",
                  color: "#6f747b",
                }}
                className="block mb-2 dark:text-white"
              >
                Latitude
                <span
                  data-key="req-text"
                  className="sd-question__required-text"
                  aria-hidden="true"
                >
                  *
                </span>
              </label>
              <input
                type="text"
                style={{
                  border: "2px solid #bdc0c4",
                }}
                id="Latitude"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              />
            </div>
            <div>
              <label
                htmlFor="Longitude"
                style={{
                  fontStyle: "normal",
                  fontWeight: "600px",
                  fontSize: "16px",
                  lineHeight: "24px",
                  fontFamily: "Open sans",
                  color: "#6f747b",
                }}
                className="block mb-2 dark:text-white"
              >
                Longitude
                <span
                  data-key="req-text"
                  className="sd-question__required-text"
                  aria-hidden="true"
                >
                  *
                </span>
              </label>
              <input
                type="text"
                style={{
                  border: "2px solid #bdc0c4",
                }}
                id="Longitude"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              />
            </div>
            <button
              className="get-gps-data"
              type="button"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6.13604 4.63604C9.65076 1.12132 15.3492 1.12132 18.864 4.63604C22.3787 8.15076 22.3787 13.8492 18.864 17.364L14.6209 21.607C13.4496 22.7783 11.5514 22.7793 10.3795 21.6074L6.13604 17.364C2.62132 13.8492 2.62132 8.15076 6.13604 4.63604ZM12.5 14C14.1569 14 15.5 12.6569 15.5 11C15.5 9.34315 14.1569 8 12.5 8C10.8431 8 9.5 9.34315 9.5 11C9.5 12.6569 10.8431 14 12.5 14Z"
                  fill="white"
                />
              </svg>
              Get GPS Data
            </button>
          </div>
        </div>
        {isRefreshingGeographies ? (
          <div className="loader h-[400px] justify-center items-center flex w-full">
            <TailSpin
              height="50"
              width="50"
              color="#005C89"
              ariaLabel="tail-spin-loading"
              radius="2"
              wrapperStyle={{}}
              wrapperClass="tailspin-loader"
              visible={true}
            />
          </div>
        ) : (
          <Map questionId={this.question._id} />
        )}
      </div>
    );
  }
}
