import React, { useEffect, useState } from "react";
// Components
import { Breadcrumb } from "Components/Users/BreadCrumb";
import { PageHeading } from "./components";

// Store Utils
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "store";
import { Stepper } from "store/addReportStepper/initialState";

// Generate links dynamically

const generateDynamicBreadcrumbLinks = (
  reportId: any,
  reportName: any,
  reportType: string | null
) => {
  switch (true) {
    case Boolean(reportId):
      return [
        { path: `/reports/list`, label: `Reports` },
        {
          path: `/reports/${reportId}/edit?type=${reportType}`,
          label: `Edit ${reportType} report`,
        },
      ];
    default:
      return [
        { path: `/reports/list`, label: `Reports` },
        {
          path: `/reports/add?type=${reportType}`,
          label: `Add ${reportType} report`,
        },
        { path: `/reports/add`, label: reportName },
      ];
  }
};

export const Header = ({ reportName }: { reportName: string }) => {
  const location = useLocation();
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const navigate = useNavigate();
  const [reportTypeName, setReportTypeName] = useState<string>("");
  // Get Notification ID
  const { reportsId } = useParams<{
    reportsId: string;
  }>();
  const queryParams = new URLSearchParams(location.search); // location.search contains the query string (e.g., '?type=basic')

  // Get the 'type' query parameter
  const reportType = queryParams.get("type"); // This will get 'basic' or 'detailed'

  const reportLinks = generateDynamicBreadcrumbLinks(
    reportsId ?? "",
    reportName,
    reportType
  );
  const handleExitReport = () => {
    navigate(-1);
  };
  useEffect(() => {
    if (reportType) {
      const action = reportsId ? "Edit" : "Add";
      setReportTypeName(`${action} ${reportType} Report`);
    }
  }, [reportsId]);

  return (
    <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0 p-5 bg-bgWhite dark:bg-secondaryLight">
      <div className="flex items-start self-stretch justify-start flex-grow">
        <div className="flex flex-col items-start justify-start flex-grow gap-2">
          <Breadcrumb links={reportLinks} />
          <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
            <PageHeading name={reportTypeName} />
            <button
              onClick={handleExitReport}
              className="flex justify-center items-center flex-grow-0 flex-shrink-0 gap-1.5 px-5 rounded-3xl border-2 border-primary hover:bg-primaryExtraLight cursor-pointer"
            >
              <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative pt-1.5 pb-[7px] ">
                <p className="flex-grow-0 flex-shrink-0 text-base font-semibold text-center text-primary">
                  Exit
                </p>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
