// Components
import { Breadcrumb } from "Components/Users/BreadCrumb";
import { PageHeading, AddReportBtn, OptionsModal } from "./components";
// Store Utils
import { useNavigate } from "react-router-dom";
import Restricted from "view/components/Restricted";
import usePermissions from "hooks/usePermissions";
import useRoles from "hooks/roles";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

const generateDynamicReportsLinks = () => {
  return [{ path: `/reports/list`, label: `Reports` }];
};

export const Header = () => {
  const navigate = useNavigate();
  const { reports } = usePermissions();
  const { isExternalDataEndReviewer } = useRoles();
  const [IsShowModal, setIsShowModal] = useState(false);
  const buttonRef = useRef<HTMLDivElement>(null);

  const handleAddReport = () => {
    setIsShowModal((prev) => !prev);
  };
  const handleClickToCreateReport = (type: string | null) => {
    navigate(`/reports/add?type=${type}`);
  };
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        buttonRef.current &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        setIsShowModal(false); // Close the modal if click is outside
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className="flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
      <div className="flex items-start self-stretch justify-start flex-grow">
        <div className="flex flex-col items-start justify-start flex-grow gap-2">
          <Breadcrumb links={generateDynamicReportsLinks()} />
          <div className="relative flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
            <PageHeading />
            {!isExternalDataEndReviewer() &&
              (reports.canCreateReports || reports.canCreateBasicReports) && (
                <AddReportBtn
                  buttonRef={buttonRef}
                  handleAddReport={handleAddReport}
                />
              )}
            <OptionsModal
              isOpen={IsShowModal}
              handleClickToCreateReport={handleClickToCreateReport}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
